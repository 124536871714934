import { QUERY_KEY_PLANS } from 'constants/query-keys';
import { queryClient } from 'providers/ReactQueryProvider';
import { useEffect } from 'react';
import { makeFetchRequest } from 'services/api';
import { ILunchPlan } from 'types/ILunchPlan';

const useParticipantsInterval = (restaurantId: string | undefined, userId: string | undefined, planId: string) => {
  return useEffect(() => {
    const interval = setInterval(() => {
      makeFetchRequest(`plans/${planId}/participants`).then((planParticipants: Partial<ILunchPlan>) => {
        queryClient.setQueryData([QUERY_KEY_PLANS, { restaurantId, userId }], (plans: ILunchPlan[] | undefined) => {
          if (plans) {
            return plans.map((plan) => (plan.id === planId ? { ...plan, ...planParticipants } : plan));
          }
        });
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [restaurantId, userId, planId]);
};

export default useParticipantsInterval;
