import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import useSnackBar from 'hooks/useSnackBar';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { Link } from 'react-router-dom';

interface ISnackbarProps {
  showNavbarAboveSnackbar: boolean;
}

const SnackBar = ({ showNavbarAboveSnackbar }: ISnackbarProps) => {
  const { message, setMessage, resourceLink, setResourceLink } = useSnackBar();

  const resetSnackBar = useCallback(() => {
    setMessage('');
    setResourceLink('');
  }, [setMessage, setResourceLink]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetSnackBar();
    }, 3000);
    return () => clearTimeout(timer);
  }, [resetSnackBar, message, resourceLink]);

  const LinkToResource = ({ children }: { children: JSX.Element }) =>
    resourceLink.length ? (
      <Link to={resourceLink} onClick={resetSnackBar} className='flex w-full'>
        {children}
      </Link>
    ) : (
      children
    );

  return (
    <div
      role='alert'
      className={classNames(
        'dark:bq-hiq-grey fixed z-10 flex w-full items-center justify-between bg-hiq-black text-white transition',
        { 'opacity-0': !message, invisible: !message },
        { 'top-navbar': showNavbarAboveSnackbar }
      )}
    >
      <LinkToResource>
        <p className='mr-10 flex h-17.5 w-full items-center px-4 py-2'>{message}</p>
      </LinkToResource>
      <button
        className='absolute right-2 top-0 flex h-full flex-row-reverse items-center'
        aria-label='Close message'
        onClick={resetSnackBar}
      >
        <CloseIcon className='fill-white' />
      </button>
    </div>
  );
};

export default SnackBar;
