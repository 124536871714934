import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import ChooseDiningAlternativeButton from 'components/ChooseDiningAlternativeButton';
import DiningAlternativesWrapper from 'components/DiningAlternativesWrapper';
import RestaurantPicker from 'components/RestaurantPicker';
import { useRestaurantQuery } from 'hooks/useRestaurantQuery';
import { IAddPlanFormValues } from 'pages/AddPlan';
import { getRestaurantMainImage, isToday } from 'utils';
import { ELunchPlanType } from 'types/ELunchPlanType';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import eatOutIconUrl from 'icons/eat-out.png';
import { ReactComponent as RobotIcon } from 'icons/robot.svg';
import takeAwayIconUrl from 'icons/takeaway-box.png';
import { ReactComponent as UnicornIcon } from 'icons/unicorn.svg';

interface IAddPlanFormProps extends FormikProps<IAddPlanFormValues> {
  allowChangeRestaurant: boolean;
  isLoading: boolean;
}

const AddPlanForm = ({
  handleSubmit,
  isLoading,
  allowChangeRestaurant,
  values,
  setFieldValue,
  touched,
  errors,
  handleChange,
}: IAddPlanFormProps) => {
  const navigate = useNavigate();
  const { data: restaurant, isFetching: isFetchingRestaurant } = useRestaurantQuery(values.restaurantId);
  const imageUrl = getRestaurantMainImage(restaurant?.mainImage || null);

  return (
    <form className='flex h-screen w-full flex-col text-hiq-black dark:text-hiq-white' onSubmit={handleSubmit}>
      <div
        className={classNames('flex grow flex-col bg-cover bg-center', {
          'bg-gradient-plan-today dark:bg-gradient-plan-dark': isToday(values.departure),
          'bg-gradient-plan-tomorrow dark:bg-gradient-plan-dark': !isToday(values.departure),
        })}
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
      >
        <div className='flex justify-end'>
          <button type='button' aria-label='Close' className='p-3' onClick={() => navigate('./..')}>
            <CloseIcon className='dark:fill-hiq-white' />
          </button>
        </div>

        <RestaurantPicker
          enabled={allowChangeRestaurant}
          onSelectRestaurant={(restaurantId: number) => setFieldValue('restaurantId', restaurantId)}
          hasError={Boolean(errors.restaurantId) && touched.restaurantId === true}
          restaurant={restaurant}
          isFetchingRestaurant={isFetchingRestaurant}
        />

        {!imageUrl && (
          <div className='flex grow items-center justify-center'>
            {isToday(values.departure) ? (
              <UnicornIcon role='img' aria-label='Pink unicorn' />
            ) : (
              <RobotIcon role='img' aria-label='Blue robot' />
            )}
          </div>
        )}
      </div>
      <DiningAlternativesWrapper
        departure={values.departure}
        onChangeDate={(date: Date | null) => setFieldValue('departure', date?.toISOString())}
      >
        <ChooseDiningAlternativeButton
          title='Take away'
          description='Are you going for take away?'
          value={ELunchPlanType.Takeaway}
          iconUrl={takeAwayIconUrl}
          onSelect={handleChange}
          isSelected={values.lunchPlanType === ELunchPlanType.Takeaway}
          hasError={Boolean(errors.lunchPlanType) && touched.lunchPlanType === true}
        />
        <ChooseDiningAlternativeButton
          title='Eat out'
          description='Are you eating out?'
          value={ELunchPlanType.EatOut}
          iconUrl={eatOutIconUrl}
          onSelect={handleChange}
          isSelected={values.lunchPlanType === ELunchPlanType.EatOut}
          hasError={Boolean(errors.lunchPlanType) && touched.lunchPlanType === true}
        />
        <Button type='submit' styleType='primary' text='Go for lunch!' disabled={isLoading} isLoading={isLoading} />
      </DiningAlternativesWrapper>
    </form>
  );
};

export default AddPlanForm;
