import ParticipantAvatar from 'components/ParticipantAvatar';
import { ILunchQUser } from 'types/ILunchQUser';

interface IParticipantList {
  participants: ILunchQUser[];
  openDrawer: () => void;
  ariaLabel: string;
}

const ParticipantList = ({ participants, openDrawer, ariaLabel }: IParticipantList) => {
  const firstFour = participants.slice(0, 4);
  const rest = participants.slice(4);

  return (
    <button
      aria-labelledby='view-participants-label'
      className='mx-3 mb-3 flex items-center justify-between'
      onClick={openDrawer}
    >
      <ul className='flex items-center space-x-1' aria-label={ariaLabel}>
        {firstFour.map((user) => {
          return (
            <li key={user.id}>
              <ParticipantAvatar user={user} />
            </li>
          );
        })}
        {rest.length > 0 && <span className='ml-2 text-sm'>+ {rest.length}</span>}
      </ul>
      {participants.length > 0 && (
        <p id='view-participants-label' className='text-xs font-medium'>
          View participants
        </p>
      )}
    </button>
  );
};

export default ParticipantList;
