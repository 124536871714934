import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useSnackBar from 'hooks/useSnackBar';
import { makeFetchRequest } from 'services/api';
import { ERestMethod } from 'types/ERestMethod';
import { IRestaurant } from 'types/IRestaurant';
import { IRestaurantFormValues } from 'types/IRestaurantFormValues';

export function useCreateRestaurantMutation() {
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const mutation = async (data: IRestaurantFormValues) => {
    return await makeFetchRequest('restaurants', ERestMethod.Post, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (data: IRestaurant) => {
      navigate(`../restaurants/${data.id}`, { replace: true });
      snackBar.setMessage('The restaurant was added!');
    },
  });
}
