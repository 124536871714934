import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LunchPlanList from 'components/LunchPlanList';
import NavHeader from 'components/NavHeader';
import PageError from 'components/PageError';
import TitleWithBackButton from 'components/TitleWithBackButton';
import AddImageCard from 'components/restaurant-view/AddImageCard';
import TabSelector from 'components/restaurant-view/TabSelector';
import ImageSlider from 'components/restaurant-view/ImageSlider';
import InfoItem from 'components/restaurant-view/InfoItem';
import RestaurantLoader from 'components/loaders/RestaurantLoader';
import usePageTitle from 'hooks/usePageTitle';
import { useRestaurantQuery } from 'hooks/useRestaurantQuery';
import { getRestaurantMainImage } from 'utils';
import { NO_ADDRESS, NO_DISTANCE_MINUTES, NO_WEBPAGE } from 'constants/no-info-paragraphs';
import { STATUS_ERROR, STATUS_LOADING } from 'constants/react-query-statuses';
import { EImageSize } from 'types/EImageSize';
import { ReactComponent as DirectionsWalkIcon } from 'icons/directions-walk.svg';
import { ReactComponent as EditPen } from 'icons/edit-pen.svg';
import { ReactComponent as LocationIcon } from 'icons/location.svg';
import { ReactComponent as PictureIcon } from 'icons/picture.svg';
import { ReactComponent as ReviewIcon } from 'icons/review.svg';
import { ReactComponent as WebsiteIcon } from 'icons/website.svg';
import Tag from 'components/Tag';
import Reviews from 'components/restaurant-view/Reviews';
import AddReviewCard from 'components/restaurant-view/AddReviewCard';
import { useReviewsQuery } from 'hooks/useReviewsQuery';

export const HighlightBorder = () => {
  return <div className='absolute bottom-0 h-0.75 w-full bg-hiq-black dark:bg-hiq-white'></div>;
};

const Restaurant = () => {
  const [currentReviewQuestionId, setCurrentReviewQuestionId] = useState(String(0));
  const [showImages, setShowImages] = useState(true);
  const [showReviews, setShowReviews] = useState(false);
  const { data: reviewQuestions } = useReviewsQuery(undefined, currentReviewQuestionId);

  const handleRefetch = () => {
    if (reviewQuestions) {
      setCurrentReviewQuestionId(String(reviewQuestions.id));
    }
  };

  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const {
    status: restaurantStatus,
    data: restaurant,
    refetch: refetchRestaurant,
  } = useRestaurantQuery(Number(restaurantId));

  usePageTitle(`LunchQ | ${restaurant ? restaurant.name : ''}`);

  if (restaurantStatus === STATUS_LOADING) {
    return <RestaurantLoader />;
  }

  if (restaurantStatus === STATUS_ERROR) {
    return <PageError description='There was an issue when trying to load restaurant' onRefresh={refetchRestaurant} />;
  }

  const mainImageUrl = getRestaurantMainImage(restaurant.mainImage, EImageSize.SMALL);

  const viewImages = () => {
    setShowImages(true);
    setShowReviews(false);
  };
  const viewReviews = () => {
    setShowImages(false);
    setShowReviews(true);
  };

  return (
    <div className='min-h-screen bg-hiq-light-pink text-hiq-black dark:bg-black  dark:text-hiq-white'>
      <NavHeader />
      <div className='flex px-4 pt-4'>
        <TitleWithBackButton title={restaurant.name} linkTo='./../..' />
        <button
          className='flex'
          aria-label='Edit restaurant'
          onClick={() => {
            navigate(`edit-restaurant`);
          }}
        >
          <EditPen className='fill-hiq-black dark:fill-hiq-white' />
          <p className='ml-1'>Edit</p>
        </button>
      </div>
      <LunchPlanList restaurantImageUrl={mainImageUrl} />
      <div className='p-4 pt-2'>
        {restaurant.address ? (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${restaurant.address.replace(
              / /g,
              '+'
            )}%2C+${restaurant.name.replace(/ /g, '+')}`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-hiq-blue visited:text-hiq-purple'
          >
            <InfoItem icon={<LocationIcon />} infoText={restaurant.address} />
          </a>
        ) : (
          <InfoItem icon={<LocationIcon />} infoText={NO_ADDRESS} />
        )}
        <InfoItem
          icon={<DirectionsWalkIcon />}
          infoText={restaurant.distanceMinutes ? `${restaurant.distanceMinutes} min` : NO_DISTANCE_MINUTES}
        />
        {restaurant.webpage ? (
          <a
            // see https://stackoverflow.com/a/48007291 for the double-slashes explanation. It doesn't work if http already exists.
            href={`${restaurant.webpage.includes('http') ? '' : '//'}${restaurant.webpage}`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-hiq-blue visited:text-hiq-purple'
          >
            <InfoItem icon={<WebsiteIcon />} infoText={restaurant.webpage} />
          </a>
        ) : (
          <InfoItem icon={<WebsiteIcon />} infoText={NO_WEBPAGE} />
        )}
      </div>
      <div className='px-4'>
        <ul className='w-full break-words'>
          {restaurant.tags.map(({ name }) => (
            <Tag key={name} text={name} isSelected />
          ))}
        </ul>
      </div>
      <div className='flex justify-around px-4 py-3 pt-2'>
        <TabSelector
          icon={<PictureIcon />}
          ariaLabel='View images'
          amount={restaurant.images.length}
          showBorder={showImages}
          onClick={viewImages}
        />
        <TabSelector
          icon={<ReviewIcon />}
          ariaLabel='View reviews'
          amount={restaurant.answers ? restaurant.answers.length : 0}
          showBorder={showReviews}
          onClick={viewReviews}
        />
      </div>

      <div className='overflow-x-auto px-4 pb-2 pt-1'>
        {showImages && (
          <ImageSlider
            mainImage={restaurant.mainImage}
            images={restaurant.images}
            restaurantId={restaurantId}
            refetchRestaurant={refetchRestaurant}
          />
        )}
        {showReviews && (
          <Reviews
            reviews={restaurant.answers}
            refetchRestaurant={refetchRestaurant}
            restaurantName={restaurant.name}
            requestNewReviewQuestion={handleRefetch}
            reviewQuestion={
              reviewQuestions ?? { id: 0, type: 'FREETEXT', question: '', answerTemplate: '', answerOption: [] }
            }
          />
        )}
      </div>
      <div className='px-4 py-2'>
        {showImages && <AddImageCard restaurantId={Number(restaurantId)} />}
        {showReviews && (
          <AddReviewCard
            restaurantName={restaurant.name}
            restaurantId={Number(restaurantId)}
            refetchRestaurant={refetchRestaurant}
            requestNewReviewQuestion={handleRefetch}
            reviewQuestion={
              reviewQuestions ?? { id: 0, type: 'FREETEXT', question: '', answerTemplate: '', answerOption: [] }
            }
          />
        )}
      </div>
    </div>
  );
};

export default Restaurant;
