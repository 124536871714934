import { PropsWithChildren } from 'react';

const ListItemWrapper = ({
  children,
  header,
  subheader,
}: PropsWithChildren<{ header: string; subheader?: string }>) => {
  return (
    <li className='relative mx-1.5 flex min-w-20.5 max-w-20.5 flex-col items-center text-center text-xs'>
      {children}
      <h3 className='mt-1 max-w-full truncate font-semibold'>{header}</h3>
      {subheader && <p className='truncate'>{subheader}</p>}
    </li>
  );
};

export default ListItemWrapper;
