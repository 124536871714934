import { ReactElement } from 'react';

interface IInfoItemProps {
  icon: ReactElement<React.SVGProps<SVGElement>>;
  infoText: string;
}

const InfoItem = ({ icon, infoText }: IInfoItemProps) => {
  return (
    <div className='mb-1 flex items-center text-sm'>
      {icon}
      <span className='ml-1 truncate'>{infoText}</span>
    </div>
  );
};

export default InfoItem;
