import { useCallback, useEffect, useRef, useState } from 'react';
import Tag from 'components/Tag';
import { ITag } from 'types/ITag';

const ResponsiveTagsList = ({ tags }: { tags: ITag[] }) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [tagsToShow, setTagsToShow] = useState(tags.length);

  /**
   * Returns the width of a Tag.
   * If tag is hidden, it makes the tag temporarily visable to be able to calculate the width.
   */
  const getTagWidth = (tag: HTMLLIElement): number => {
    const displayTag = tag.style.display;
    tag.style.display = 'inline-block';
    const tagWidth = tag.offsetWidth;
    tag.style.display = displayTag;
    return tagWidth;
  };

  const getTagsToShow = useCallback((list: HTMLUListElement): number => {
    const ellipsisTag = list.lastChild as HTMLLIElement;
    const containerWidth = list.offsetWidth - 10;

    let tagsToShow = 0;
    let totalTagsWidth = 0;

    let currentTag: ChildNode | null = list.childNodes.item(0);

    while (currentTag !== null && !currentTag.isEqualNode(ellipsisTag)) {
      const tagElement = currentTag as HTMLLIElement;

      const margins = 8;
      totalTagsWidth += getTagWidth(tagElement) + margins;

      if ((currentTag.nextSibling as HTMLLIElement).isEqualNode(ellipsisTag) && totalTagsWidth <= containerWidth) {
        return tagsToShow + 1;
      }

      if (totalTagsWidth + ellipsisTag.offsetWidth >= containerWidth) {
        return tagsToShow;
      }

      tagsToShow++;
      currentTag = currentTag.nextSibling;
    }

    return tagsToShow;
  }, []);

  useEffect(() => {
    const list = listRef.current;

    if (!list) {
      return;
    }

    const handleResizeEvent = () => {
      setTagsToShow(getTagsToShow(list));
    };

    if ('ResizeObserver' in window) {
      const observer = new ResizeObserver(() => {
        handleResizeEvent();
      });
      observer.observe(list);
      return () => observer.unobserve(list);
    }
  }, [getTagsToShow]);

  return (
    <>
      <ul ref={listRef} className='overflow-x-hidden whitespace-nowrap'>
        {tags.map(({ name }, index) => (
          <Tag key={name} text={name} visible={index < tagsToShow} />
        ))}
        {<Tag text='...' visible={tagsToShow < tags.length} />}
      </ul>
    </>
  );
};

export default ResponsiveTagsList;
