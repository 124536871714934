import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_TAGS } from 'constants/query-keys';
import { ITag } from 'types/ITag';

export function useTagsQuery(searchQuery?: string) {
  const params = new URLSearchParams();

  if (searchQuery) {
    params.append('searchQuery', searchQuery);
  }

  return useQuery<ITag[]>({
    queryKey: [QUERY_KEY_TAGS, { searchQuery }],
    retry: false,
    queryFn: () => makeFetchRequest(`restaurants/tags?${params.toString()}`),
  });
}
