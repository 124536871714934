import { ReactElement } from 'react';
import { HighlightBorder } from 'pages/Restaurant';

interface ITabSelectorProps {
  icon: ReactElement<React.SVGProps<SVGElement>>;
  ariaLabel: string;
  amount: number;
  showBorder: boolean;
  onClick: () => void;
}

const TabSelector = ({ icon: Icon, ariaLabel, amount, showBorder, onClick }: ITabSelectorProps) => {
  return (
    <button className='relative flex w-full items-center justify-center py-3' onClick={onClick} aria-label={ariaLabel}>
      {Icon}
      <span className='ml-1 text-sm font-semibold'>{amount}</span>
      {showBorder && <HighlightBorder />}
    </button>
  );
};

export default TabSelector;
