import { Dispatch, SetStateAction, useContext, useRef } from 'react';
import Drawer from 'react-modern-drawer';
import SortOption from 'components/SortOption';
import useClickOutside from 'hooks/useClickOutside';
import { ESortOption } from 'types/ESortingOptions';
import { TAvailableSortOption } from 'types/TAvailableSortOption';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { SearchContext } from 'providers/SearchProvider';

interface IRestaurantSortingProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sortOptionLabels: Record<TAvailableSortOption, string>;
}

const RestaurantSorting = ({ open, setOpen, sortOptionLabels }: IRestaurantSortingProps) => {
  const { sortOption } = useContext(SearchContext);
  const drawerRef = useRef<HTMLDivElement>(null);
  useClickOutside(drawerRef, open, () => setOpen(false));

  return (
    <div aria-hidden={open}>
      <Drawer
        open={open}
        direction='bottom'
        lockBackgroundScroll={true}
        className='rounded-lg dark:bg-hiq-black'
        style={{ height: 'auto' }}
      >
        <div ref={drawerRef} className='h-auto w-full'>
          <div className='relative flex items-center justify-center p-4'>
            <h2 className='text-xl font-semibold'>Sort restaurants</h2>
            <button
              aria-label='Close sorting options view'
              className='absolute right-3 p-1'
              onClick={() => setOpen(false)}
            >
              <CloseIcon className='fill-hiq-black dark:fill-hiq-white' />
            </button>
          </div>
          <ul className='pb-4'>
            <SortOption
              name={ESortOption.Default}
              label={sortOptionLabels[ESortOption.Default]}
              active={sortOption === ESortOption.Default}
            />
            <SortOption
              name={ESortOption.DistanceMinutes}
              label={sortOptionLabels[ESortOption.DistanceMinutes]}
              active={sortOption === ESortOption.DistanceMinutes}
            />
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default RestaurantSorting;
