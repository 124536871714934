import classNames from 'classnames';
import { Link } from 'react-router-dom';
import donutIcon from 'icons/donut.svg';
import { useState } from 'react';

interface IRestaurantAvatarProps {
  imageUrl?: string;
  link?: string;
  plannedForToday: boolean;
  plannedForLater: boolean;
  userId?: string | undefined;
}

const RestaurantAvatar = ({ imageUrl, link, plannedForToday, plannedForLater, userId }: IRestaurantAvatarProps) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Link
      to={link || ''}
      state={{ userId }}
      className={classNames(
        'max-h-20.5 flex min-h-20.5 min-w-20.5 max-w-20.5 items-center justify-center rounded-full',
        {
          'bg-gradient-border-today': plannedForToday,
          'bg-gradient-border-tomorrow': plannedForLater,
        }
      )}
    >
      <img
        className={classNames('h-19 w-19 rounded-full border-3 object-cover', {
          'border-transparent': !plannedForToday && !plannedForLater,
          'border-white dark:border-hiq-black': plannedForToday || plannedForLater,
          'bg-white': !imageUrl || imageError,
        })}
        src={imageError || !imageUrl ? donutIcon : imageUrl}
        onError={handleImageError}
        alt=''
      />
    </Link>
  );
};

export default RestaurantAvatar;
