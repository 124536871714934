import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import talkBubbleIconUrl from 'icons/talk-bubble.png';
import ReviewForm from './ReviewForm';
import { IReviewFormValues } from 'types/IReviewFormValues';
import { useEffect, useState } from 'react';
import { useCreateReviewMutation } from 'hooks/useCreateReviewMutation';
import { IReviewQuestion } from 'types/IReviewQuestion';

interface IAddReviewCardProps {
  restaurantId: number;
  restaurantName: string;
  refetchRestaurant: () => void;
  requestNewReviewQuestion: () => void;
  reviewQuestion: IReviewQuestion;
}

const AddReviewCard = ({
  restaurantId,
  restaurantName,
  refetchRestaurant,
  requestNewReviewQuestion,
  reviewQuestion,
}: IAddReviewCardProps) => {
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [currentReviewQuestion, setCurrentReviewQuestion] = useState<IReviewQuestion | null>(null);
  const [reviewData, setReviewData] = useState<{
    questionId: number;
    question: string;
    answer: string;
  }>({
    questionId: 0,
    question: '',
    answer: '',
  });

  useEffect(() => {
    if (reviewQuestion.question !== '' && reviewQuestion.question !== currentReviewQuestion?.question) {
      setCurrentReviewQuestion(reviewQuestion);
    }
  }, [reviewQuestion, currentReviewQuestion?.question]);

  const handleOpenReviewForm = () => {
    setReviewData({
      questionId: reviewQuestion.id,
      question: reviewQuestion.question,
      answer: '',
    });
    setIsReviewFormOpen(true);
    handleRandomizeQuestion();
  };

  const handleCloseReviewForm = () => {
    setIsReviewFormOpen(false);
  };

  const handleRandomizeQuestion = () => {
    requestNewReviewQuestion();
    if (reviewQuestion) {
      setReviewData({
        questionId: reviewQuestion.id,
        question: reviewQuestion.question,
        answer: '',
      });
    }
  };

  const createReviewMutation = useCreateReviewMutation(() => {
    refetchRestaurant();
    handleCloseReviewForm();
    handleRandomizeQuestion();
  });

  const onSubmit = (values: IReviewFormValues) => {
    createReviewMutation.mutate({
      restaurantId: restaurantId,
      answers: [
        {
          questionId: reviewData.questionId,
          answerText: values.answer,
        },
      ],
    });
  };

  return (
    <>
      <button
        className={'my-3 flex h-21 w-full items-center rounded-md bg-hiq-muted-tea pl-4 pr-3 dark:bg-hiq-black'}
        onClick={handleOpenReviewForm}
      >
        <div className='flex min-w-20.5 justify-center'>
          <img src={talkBubbleIconUrl} alt='' />
        </div>

        <div className='ml-2 text-left'>
          <h3 className='mb-1 text-sm font-semibold text-hiq-moss-green dark:text-hiq-white'>
            How do you like this place?
          </h3>
          <p className='text-sm text-hiq-moss-green dark:text-hiq-muted-pink'>Answer a question!</p>
        </div>

        <div className='ml-auto'>
          <PlusIcon className='fill-hiq-moss-green dark:fill-hiq-muted-pink' />
        </div>
      </button>
      {reviewData && (
        <ReviewForm
          initialValues={{
            question: reviewData.question,
            answer: reviewData.answer,
            questionId: reviewData.questionId,
          }}
          onSubmit={onSubmit}
          isLoading={false}
          isOpen={isReviewFormOpen}
          onRandomizeQuestion={handleRandomizeQuestion}
          handleCloseDrawer={handleCloseReviewForm}
          restaurantName={restaurantName}
        />
      )}
    </>
  );
};

export default AddReviewCard;
