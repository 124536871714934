import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';

interface IAlertMessageProps {
  title?: string;
  description: string;
  className?: string;
  onRefresh?: () => void;
  iconAltText?: string;
  icon: string;
}

const AlertMessage = ({
  title,
  description,
  className = 'mt-40',
  onRefresh,
  iconAltText = '',
  icon,
}: IAlertMessageProps) => (
  <div role='alert' className={`flex flex-col items-center ${className}`}>
    <img src={icon} alt={iconAltText} />
    <h1 className='mt-6 font-semibold'>{title}</h1>
    <p className='mt-2 w-5/6 text-center text-sm'>{description}</p>
    {onRefresh && (
      <button aria-label='Refresh' className='p-5' onClick={onRefresh}>
        <RefreshIcon className='fill-hiq-black dark:fill-hiq-white' />
      </button>
    )}
  </div>
);

export default AlertMessage;
