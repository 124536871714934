import classNames from 'classnames';
import { useContext, useState } from 'react';
import RestaurantSortingDrawer from 'components/RestaurantSortingDrawer';
import { ESortOption } from 'types/ESortingOptions';
import { TAvailableSortOption } from 'types/TAvailableSortOption';
import { ReactComponent as SortIcon } from 'icons/sort.svg';
import { SearchContext } from 'providers/SearchProvider';

const SortButton = () => {
  const [showSortOptions, setShowSortOptions] = useState<boolean>(false);
  const { sortOption } = useContext(SearchContext);
  const sortOptionLabels: Record<TAvailableSortOption, string> = {
    [ESortOption.Default]: 'Default (A-Ö, plans first)',
    [ESortOption.DistanceMinutes]: 'Distance from office (closest)',
  };

  const isDefaultSortOption = sortOption === ESortOption.Default;
  const label = isDefaultSortOption
    ? 'Sort'
    : sortOptionLabels[sortOption as TAvailableSortOption] === sortOptionLabels.DISTANCE_MINUTES
    ? 'Distance'
    : sortOptionLabels[sortOption as TAvailableSortOption];

  return (
    <div>
      <button
        aria-label='Sort restaurants'
        className={classNames('flex space-x-1', { 'text-hiq-pink dark:text-hiq-muted-pink': !isDefaultSortOption })}
        onClick={() => setShowSortOptions(true)}
      >
        <SortIcon
          className={
            isDefaultSortOption ? 'fill-hiq-black dark:fill-hiq-white' : 'fill-hiq-pink dark:fill-hiq-muted-pink'
          }
        />
        <span>{label}</span>
      </button>
      <RestaurantSortingDrawer
        open={showSortOptions}
        setOpen={setShowSortOptions}
        sortOptionLabels={sortOptionLabels}
      />
    </div>
  );
};

export default SortButton;
