import debounce from 'lodash/debounce';
import { ChangeEvent, MutableRefObject, useCallback, useContext } from 'react';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { SearchContext } from 'providers/SearchProvider';

interface ISearchBarProps {
  onSearch: (searchQuery: string) => void;
  searchInputRef: MutableRefObject<HTMLInputElement | null>;
  onFocus: () => void;
  onBlur: () => void;
  toggleFilter: () => void;
  showFilter: boolean;
  activeFilters: number;
}

const SearchBar = ({
  onSearch,
  searchInputRef,
  onFocus,
  onBlur,
  toggleFilter,
  showFilter,
  activeFilters,
}: ISearchBarProps) => {
  const { searchQuery } = useContext(SearchContext);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onSearch(value);
    },
    [onSearch]
  );

  const clearSearch = useCallback(() => {
    if (searchInputRef.current) {
      // Since this input field is using debounce it doesn't work well
      // for it to be a "controlled input", i.e. sending in the
      // searchQuery as a prop and using value={searchQuery}. So
      // we are clearing the value with a ref instead.
      searchInputRef.current.value = '';
      onSearch('');
      searchInputRef.current.focus();
    }
  }, [onSearch, searchInputRef]);

  const debouncedSearch = debounce(handleSearch, 500);

  return (
    <div className='flex w-full py-5'>
      <div className='relative w-full pl-5'>
        <span className='pointer-events-none absolute inset-y-0 left-8 flex items-center'>
          <SearchIcon className='fill-hiq-black dark:fill-hiq-white' />
        </span>
        <input
          aria-label='Search for restaurant'
          className='w-full rounded-md border py-2 pl-11 pr-7 text-base outline-hiq-black dark:border-hiq-grey dark:bg-secondary-black dark:text-hiq-white dark:placeholder:text-hiq-white'
          onChange={debouncedSearch}
          id='search'
          type='text'
          placeholder='Search for restaurant'
          ref={searchInputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          defaultValue={searchQuery}
        />

        <button
          onClick={clearSearch}
          aria-label='Clear search'
          className='absolute inset-y-0 right-0 flex w-8 items-center'
        >
          {searchInputRef.current?.value ? <CloseIcon className='h-5 w-5 dark:fill-hiq-white' /> : null}
        </button>
      </div>
      <button className='relative mx-1 px-3 dark:fill-hiq-white' onClick={toggleFilter} aria-label='Show filters'>
        {activeFilters > 0 && !showFilter && (
          <p className='border-inset absolute right-1.5 top-0.5 flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-hiq-pink text-xs text-white dark:bg-hiq-muted-pink'>
            {activeFilters}
          </p>
        )}
        <FilterIcon className={`${showFilter && 'fill-hiq-pink dark:fill-hiq-muted-pink'}`} />
      </button>
    </div>
  );
};

export default SearchBar;
