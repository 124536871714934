import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PageError from 'components/PageError';
import { useCurrentAccount } from 'hooks/useCurrentAccount';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import AddPlan from 'pages/AddPlan';
import AddRestaurant from 'pages/AddRestaurant';
import LunchPlan from 'pages/LunchPlan';
import OfficeSettings from 'pages/OfficeSettings';
import Restaurant from 'pages/Restaurant';
import StartPage from 'pages/StartPage';
import { loginRequest } from 'authConfig';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants/react-query-statuses';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';
import EditRestaurantLoader from 'components/loaders/EditRestaurantLoader';

const router = createBrowserRouter([
  {
    path: '/',
    element: <StartPage />,
  },
  {
    path: 'plans/:planId', // when visiting a plan through the horizontal lunch plan list on start page
    element: <LunchPlan />,
  },
  {
    path: 'restaurants/:restaurantId/plans/:planId', // when visiting a plan on a specific restaurant
    element: <LunchPlan />,
  },
  {
    path: 'add-plan', // when adding plan from start page
    element: <AddPlan />,
  },
  {
    path: 'add-restaurant',
    element: <AddRestaurant />,
  },
  {
    path: 'restaurants/:restaurantId',
    element: <Restaurant />,
  },
  {
    path: 'restaurants/:restaurantId/add-plan', // when adding plan from specific restaurant
    element: <AddPlan />,
  },
  {
    path: 'restaurants/:restaurantId/edit-restaurant', // when editing a specific restaurant
    element: <EditRestaurantLoader />,
  },
  {
    path: 'office-settings',
    element: <OfficeSettings />,
  },
]);

function ErrorComponent({ error }: MsalAuthenticationResult) {
  return <p>An error occurred when signing in: {error?.errorMessage}</p>;
}

function LoadingComponent() {
  return <p>Signing in...</p>;
}

function App() {
  const account = useCurrentAccount();
  const { data: user, status: userStatus, refetch: refetchUser } = useCurrentUserQuery(!!account);

  if (account) {
    return (
      <>
        {userStatus === STATUS_LOADING && (
          <div className='flex min-h-screen items-center justify-center'>
            <LoadingSpinnerIcon role='alert' className='h-7 w-7 animate-spin' />
          </div>
        )}
        {userStatus === STATUS_ERROR ||
          (userStatus === STATUS_SUCCESS && !user && (
            <PageError description='Something went wrong when trying to load user' onRefresh={refetchUser} />
          ))}
        {userStatus === STATUS_SUCCESS && user && <RouterProvider router={router} />}
      </>
    );
  } else {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      />
    );
  }
}

export default App;
