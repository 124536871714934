import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_CURRENT_USER } from 'constants/query-keys';
import { ERestMethod } from 'types/ERestMethod';
import { ILunchQUser } from 'types/ILunchQUser';

export function useCurrentUserMutation() {
  const mutation = async (data: Partial<ILunchQUser>) => {
    return await makeFetchRequest('users/me', ERestMethod.Patch, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (data: ILunchQUser) => {
      queryClient.setQueryData([QUERY_KEY_CURRENT_USER], data);
    },
  });
}
