import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_PLANS, QUERY_KEY_RESTAURANT } from 'constants/query-keys';
import { ERestMethod } from 'types/ERestMethod';
import { IRestaurant } from 'types/IRestaurant';
import { IRestaurantFormValues } from 'types/IRestaurantFormValues';
import useSnackBar from './useSnackBar';

export function useEditRestaurantMutation(restaurantId: number, origin: string, onSuccessCallback?: () => void) {
  const snackBar = useSnackBar();

  const mutation = async (data: { mainImageId: number } | IRestaurantFormValues) => {
    return await makeFetchRequest(`restaurants/${restaurantId}`, ERestMethod.Patch, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (data: IRestaurant) => {
      origin === 'imageSlider'
        ? snackBar.setMessage(`Image has been set as main photo.`)
        : snackBar.setMessage(`Restaurant has been edited`);

      queryClient.setQueryData([QUERY_KEY_RESTAURANT, { restaurantId }], data);
      queryClient.invalidateQueries([QUERY_KEY_PLANS, { restaurantId: restaurantId.toString() }]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
}
