import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_RESTAURANT } from 'constants/query-keys';
import { IRestaurant } from 'types/IRestaurant';

export function useRestaurantQuery(restaurantId: number | undefined) {
  return useQuery<IRestaurant>({
    queryKey: [QUERY_KEY_RESTAURANT, { restaurantId }],
    retry: false,
    queryFn: () => makeFetchRequest(`restaurants/${restaurantId}`),
    enabled: Boolean(restaurantId),
  });
}
