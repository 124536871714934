import { Link } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';

interface INavLinkItemProps {
  linkText: string;
  route: string;
}

const NavLinkItem = ({ linkText, route }: INavLinkItemProps) => {
  return (
    <li className='mx-4 mt-3 flex items-center rounded-md py-3 pl-2 pr-2 active:bg-hiq-pink active:text-white'>
      <Link
        aria-label={`${linkText}`}
        className='ml-3 flex w-full items-center justify-between overflow-auto hover:cursor-pointer'
        to={`${route}`}
      >
        <h3>{linkText}</h3>
        <RightArrowIcon className={'fill-current'} />
      </Link>
    </li>
  );
};
export default NavLinkItem;
