import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react';

interface ISnackBarContext {
  setMessage: Dispatch<SetStateAction<string>> | ((msg: string) => void);
  message: string;
  setResourceLink: Dispatch<SetStateAction<string>> | ((msg: string) => void);
  resourceLink: string;
}

export const SnackBarContext = createContext<ISnackBarContext>({
  setMessage: () => {},
  message: '',
  setResourceLink: () => {},
  resourceLink: '',
});

const SnackBarProvider = ({ children }: PropsWithChildren) => {
  const [message, setMessage] = useState('');
  const [resourceLink, setResourceLink] = useState<string>('');

  return (
    <SnackBarContext.Provider value={{ setMessage, message, setResourceLink, resourceLink }}>
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
