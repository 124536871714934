import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

interface IFilterWrapperProps {
  clearFilters: () => void;
  showFilter: boolean;
  activeFilters: number;
}
const FilterWrapper: FC<PropsWithChildren<IFilterWrapperProps>> = ({
  clearFilters,
  showFilter,
  activeFilters,
  children,
}) => {
  return (
    <div className={classNames('mx-5', { hidden: !showFilter })} aria-hidden={!showFilter}>
      {children}
      <div className='flex justify-end p-5'>
        <button
          onClick={clearFilters}
          className='text-sm font-semibold disabled:text-hiq-grey'
          disabled={activeFilters === 0}
        >
          Clear filters
        </button>
      </div>
    </div>
  );
};

export default FilterWrapper;
