const FormLoader = () => {
  return (
    <div role='alert' className='animate-pulse'>
      <div className='mx-4 mt-7 h-12 rounded-lg bg-hiq-white dark:bg-hiq-grey'></div>
      <div className='mx-4 mt-7 h-12 rounded-lg bg-hiq-white dark:bg-hiq-grey'></div>
      <div className='mx-4 mt-7 h-12 rounded-lg bg-hiq-white dark:bg-hiq-grey'></div>
    </div>
  );
};

export default FormLoader;
