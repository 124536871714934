import Button from 'components/Button';
import NavLinkItem from 'components/NavLinkItem';
import UserAvatar from 'components/UserAvatar';
import { useCurrentAccount } from 'hooks/useCurrentAccount';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { msalInstance } from 'providers/AuthProvider';
import { STATUS_ERROR, STATUS_LOADING } from 'constants/react-query-statuses';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import Drawer from 'react-modern-drawer';
import ToggleSwitch from './ToggleSwitch';
import { useContext } from 'react';
import { DarkModeContext } from 'providers/DarkModeProvider';

interface INavDrawerProps {
  showNavDrawer: boolean;
  handleCloseNavDrawer: () => void;
}

const NavDrawer = ({ showNavDrawer, handleCloseNavDrawer: handleClose }: INavDrawerProps) => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  const { data: user, status: userStatus } = useCurrentUserQuery();
  const account = useCurrentAccount();

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (userStatus === STATUS_LOADING) {
    return <p role='alert'>Loading...</p>;
  }

  if (userStatus === STATUS_ERROR) {
    return <p className='text-red-500'>Something went wrong when loading user details...</p>;
  }

  if (!user) {
    return null;
  }

  const getUserName = (): string => {
    return `${user.firstName} ${user.lastName}`;
  };

  const logOut = async () => {
    await msalInstance.logoutRedirect({ account });
  };

  return (
    <Drawer
      open={showNavDrawer}
      direction='right'
      onClose={handleClose}
      style={{ width: 'auto', boxShadow: 'none' }}
      overlayOpacity={0.25}
    >
      {showNavDrawer && (
        <div className='flex h-full min-w-elevenTwelfths flex-col items-center bg-hiq-light-pink dark:bg-hiq-black dark:text-hiq-white md:min-w-quart'>
          <button
            type='button'
            aria-label='Close'
            className='close-button-class self-end p-5'
            name='Close'
            onClick={handleClose}
          >
            <CloseIcon className='fill-hiq-black dark:fill-hiq-white' />
          </button>

          <div className='my-5'>
            <UserAvatar />
            <h1>{getUserName()}</h1>
          </div>

          <ul className='w-full'>
            <NavLinkItem linkText='Add a restaurant' route='/add-restaurant' />
            <NavLinkItem linkText='Office settings' route='/office-settings' />
          </ul>

          <div className='mt-6 flex w-full items-center justify-between pl-9 pr-7'>
            <h3>Dark side</h3>
            <ToggleSwitch isChecked={darkMode} testId='dark-mode-checkbox' onToggle={handleToggleDarkMode} />
          </div>

          <div className='absolute bottom-10 flex w-full flex-col justify-center'>
            <Button
              type='button'
              styleType='secondary'
              text='Sign out'
              disabled={false}
              isLoading={false}
              onClick={() => logOut()}
            />
          </div>
        </div>
      )}
      ;
    </Drawer>
  );
};

export default NavDrawer;
