import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_PLANS } from 'constants/query-keys';
import { ILunchPlan } from 'types/ILunchPlan';

export function useLunchPlansQuery(options?: { restaurantId?: string; userId?: string | undefined }) {
  const params = new URLSearchParams();
  const restaurantId = options?.restaurantId;
  const userId = options?.userId;

  if (restaurantId) {
    params.append('restaurantId', restaurantId);
  }
  if (userId) {
    params.append('userId', userId);
  }

  return useQuery<ILunchPlan[]>({
    queryKey: [QUERY_KEY_PLANS, { restaurantId, userId }],
    retry: false,
    queryFn: () => makeFetchRequest(`plans?${params.toString()}`),
  });
}
