import classNames from 'classnames';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';

interface IButtonProps {
  type: 'button' | 'submit';
  styleType: 'primary' | 'secondary' | 'danger';
  text: string;
  onClick?: () => void;
  disabled: boolean;
  isLoading: boolean;
}

const Button = ({ type = 'button', styleType, text, onClick, disabled, isLoading }: IButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classNames('mx-3 mt-3 flex justify-center rounded-full border-2 py-4 font-semibold', {
        'border-hiq-black bg-hiq-black text-white active:border-hiq-pink active:bg-hiq-pink dark:border-hiq-black dark:bg-hiq-muted-pink dark:text-hiq-black dark:active:border-hiq-pink dark:active:bg-hiq-pink':
          styleType === 'primary',
        'border-hiq-black active:border-hiq-pink active:bg-hiq-pink active:text-white dark:border-hiq-white dark:active:border-hiq-pink dark:active:text-hiq-black':
          styleType === 'secondary',
        'border-hiq-danger bg-hiq-danger text-white active:border-hiq-pressed-red active:bg-hiq-pressed-red dark:border-hiq-danger-muted dark:bg-hiq-danger-muted':
          styleType === 'danger',
      })}
      onClick={onClick}
    >
      {isLoading ? <LoadingSpinnerIcon role='alert' className='mr-3 h-5 w-5 animate-spin' /> : text}
    </button>
  );
};

export default Button;
