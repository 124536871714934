import NavHeader from 'components/NavHeader';
import OfficeOption from 'components/OfficeOption';
import PageError from 'components/PageError';
import TitleWithBackButton from 'components/TitleWithBackButton';
import OfficeOptionsLoader from 'components/loaders/OfficeOptionsLoader';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { useOfficesQuery } from 'hooks/useOfficesQuery';
import usePageTitle from 'hooks/usePageTitle';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants/react-query-statuses';

const OfficeSettings = () => {
  usePageTitle('LunchQ | Office Settings');
  const { data: user } = useCurrentUserQuery();
  const { data: offices, status: officesStatus, refetch: refetchOffices } = useOfficesQuery();

  const selectedOffice = offices?.find((office) => office.id === user?.officeId);

  return (
    <div className='flex h-screen flex-col bg-hiq-light-pink dark:bg-black dark:text-hiq-white'>
      <NavHeader />
      <div className='flex px-4 pt-4'>
        <TitleWithBackButton title='Office settings' linkTo='..' />
      </div>

      <h2 className='mx-8 mr-auto mt-4 truncate text-xl'>
        Your office is set to <span className='font-semibold'>{selectedOffice?.name || '...'}</span>
      </h2>
      <p className='mx-8 my-2 text-sm'>Select the location you want to use LunchQ in.</p>
      {officesStatus === STATUS_LOADING && <OfficeOptionsLoader length={3} />}
      {officesStatus === STATUS_ERROR && (
        <PageError description='Something went wrong when trying to load offices' onRefresh={refetchOffices} />
      )}
      {officesStatus === STATUS_SUCCESS &&
        offices.map(({ id, name }) => {
          return <OfficeOption key={id} text={name} officeId={id} isSelected={id === user?.officeId} />;
        })}
    </div>
  );
};

export default OfficeSettings;
