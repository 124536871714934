import classNames from 'classnames';
import { ChangeEvent, ReactElement } from 'react';

interface ITextInputProps {
  className?: string;
  id: string;
  value: string;
  label: string;
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  rightIcon?: ReactElement<React.SVGProps<SVGElement>>;
  maxLength?: number;
}

const TextInput = ({ className = '', id, value, label, error, onChange, rightIcon, maxLength }: ITextInputProps) => {
  return (
    <label className={`relative mx-4 text-sm ${className}`} htmlFor={id}>
      <span className='absolute left-3 top-negative-2 bg-white px-1 dark:bg-hiq-black'>{label}</span>
      <input
        id={id}
        value={value}
        onChange={onChange}
        type='text'
        className={classNames(
          'dark:focus:borde-hiq-white h-12 w-full rounded-lg border pl-4 pr-9 outline-none focus:border-2 focus:border-hiq-black dark:bg-hiq-black',
          {
            'border-2 border-hiq-danger dark:border-hiq-danger-muted': Boolean(error),
            'border-hiq-grey dark:border-hiq-grey': !Boolean(error),
          }
        )}
        maxLength={maxLength}
      />
      <span className='absolute right-3 top-3'>{rightIcon}</span>
      {error && <span className='ml-4 text-hiq-danger dark:text-hiq-danger-muted'>{error}</span>}
    </label>
  );
};

export default TextInput;
