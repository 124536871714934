import { useState } from 'react';
import { ReactComponent as DotButton } from 'icons/dot-button.svg';
import classNames from 'classnames';
import { IReviewQuestion } from 'types/IReviewQuestion';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { useDeleteReviewMutation } from 'hooks/useDeleteReviewMutation';
import { useEditReviewMutation } from 'hooks/useEditReviewMutation';
import { IReview } from 'types/IReview';
import { IReviewFormValues } from 'types/IReviewFormValues';
import NoContentYet from './NoContentYet';
import ParticipantAvatar from 'components/ParticipantAvatar';
import ReviewForm from './ReviewForm';
import ReviewOptionsDrawer from './ReviewOptionsDrawer';

interface IReviewProps {
  reviews: IReview[] | null;
  refetchRestaurant: () => void;
  restaurantName: string;
  requestNewReviewQuestion: () => void;
  reviewQuestion: IReviewQuestion;
}
const Reviews = ({
  reviews,
  refetchRestaurant,
  restaurantName,
  requestNewReviewQuestion,
  reviewQuestion,
}: IReviewProps) => {
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [isReviewOptionsDrawerOpen, setIsReviewOptionsDrawerOpen] = useState(false);

  const [reviewOptionsDrawer, setReviewOptionsDrawer] = useState<{
    reviewId: number;
    questionId: number;
    question: string;
    answer: string;
  }>({
    reviewId: 0,
    questionId: 0,
    question: '',
    answer: '',
  });

  const { data: user } = useCurrentUserQuery();

  const openReviewOptionsDrawer = (reviewId: number, questionId: number, question: string, answer: string) => {
    setIsReviewOptionsDrawerOpen(true);
    setReviewOptionsDrawer({ reviewId, questionId, question, answer });
  };

  const handleCloseReviewOptionsDrawer = () => {
    setIsReviewOptionsDrawerOpen(false);
  };

  const deleteImageMutation = useDeleteReviewMutation(() => {
    refetchRestaurant();
    handleCloseReviewEditor();
    handleCloseReviewOptionsDrawer();
  });

  const handleDeleteReview = (reviewId: number) => {
    deleteImageMutation.mutate(reviewId);
  };

  const handleRandomizeQuestion = () => {
    requestNewReviewQuestion();
    if (reviewQuestion) {
      setReviewOptionsDrawer({
        reviewId: reviewOptionsDrawer.reviewId,
        questionId: reviewQuestion.id,
        question: reviewQuestion.question,
        answer: '',
      });
    }
  };

  const handleOpenReviewEditor = () => {
    setIsReviewFormOpen(true);
    handleCloseReviewOptionsDrawer();
  };

  const handleCloseReviewEditor = () => {
    setIsReviewFormOpen(false);
  };

  const editReviewMutation = useEditReviewMutation(reviewOptionsDrawer.reviewId, () => {
    refetchRestaurant();
    handleCloseReviewEditor();
    handleCloseReviewOptionsDrawer();
    handleRandomizeQuestion();
  });

  const onSubmit = (values: IReviewFormValues) => {
    editReviewMutation.mutate({
      questionId: values.questionId,
      answerText: values.answer,
    });
  };

  if (!reviews || reviews.length === 0) {
    return <NoContentYet type='reviews' />;
  }

  return (
    <>
      <ul aria-label='List of reviews'>
        {reviews.map(({ id, questionId, answerText, questionText, lunchQUser }) => {
          return (
            <li
              key={id}
              className={classNames('mt-5 rounded-mx bg-white p-6 first:mt-1 dark:bg-hiq-black', {
                'pt-1': user?.id === lunchQUser.id,
                'pt-6': user?.id !== lunchQUser.id,
              })}
            >
              {user?.id === lunchQUser.id && (
                <div className='flex justify-end'>
                  <button
                    aria-label='Edit/delete review drawer'
                    onClick={() => openReviewOptionsDrawer(id, questionId, questionText, answerText)}
                  >
                    <DotButton className='fill-hiq-black dark:fill-hiq-white' />
                  </button>
                </div>
              )}

              <p>{questionText}</p>
              <p className='mt-4 text-2xl font-semibold text-hiq-pink dark:text-hiq-muted-pink'>{answerText}</p>
              <div className='mt-2.5 flex'>
                <ParticipantAvatar user={lunchQUser} />
                <div className='ml-3 flex items-center'>
                  {lunchQUser.firstName} {lunchQUser.lastName}
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      {reviewOptionsDrawer.reviewId !== 0 && (
        <ReviewOptionsDrawer
          reviewId={reviewOptionsDrawer.reviewId}
          isOpen={isReviewOptionsDrawerOpen}
          closeDrawer={handleCloseReviewOptionsDrawer}
          handleDeleteReview={handleDeleteReview}
          openReviewForm={handleOpenReviewEditor}
        />
      )}

      {reviewOptionsDrawer.question !== '' && (
        <ReviewForm
          initialValues={{
            question: reviewOptionsDrawer.question,
            answer: reviewOptionsDrawer.answer,
            questionId: reviewOptionsDrawer.questionId,
          }}
          onSubmit={onSubmit}
          isLoading={false}
          isOpen={isReviewFormOpen}
          onRandomizeQuestion={handleRandomizeQuestion}
          handleCloseDrawer={handleCloseReviewEditor}
          restaurantName={restaurantName}
        />
      )}
    </>
  );
};

export default Reviews;
