export enum EImageSize {
  /**
   * Max width: 200
   */
  SMALL = 'small',

  /**
   * Max width: 1920
   */
  LARGE = 'large',
}
