import classNames from 'classnames';

interface ITagSuggestionProps {
  text: string;
  onSelect: (value: string) => void;
}

const TagSuggestion = ({ text, onSelect }: ITagSuggestionProps) => {
  return (
    <li className='mr-2 mt-1.5 inline-block text-xs font-semibold leading-3' role={undefined}>
      <label
        className={classNames('checked:font-lg block h-7 items-center rounded-full px-4 py-2', `bg-hiq-white`, {
          'cursor-pointer': onSelect,
        })}
        htmlFor={text}
      >
        {onSelect && (
          <input
            onChange={(e) => onSelect(e.target.value)}
            type='checkbox'
            className='hidden'
            id={text}
            value={text}
            checked={false}
          />
        )}
        <span className='align-super dark:text-black'>{text}</span>
      </label>
    </li>
  );
};

export default TagSuggestion;
