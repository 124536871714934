import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import LunchPlanListItem from 'components/LunchPlanListItem';
import UserPlansListItem from 'components/UserPlansListItem';
import AvatarLoader from 'components/loaders/AvatarLoader';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { useLunchPlansQuery } from 'hooks/useLunchPlansQuery';
import { isActiveLunchPlan } from 'utils';
import { STATUS_ERROR, STATUS_LOADING } from 'constants/react-query-statuses';

interface ILunchPlanListProps {
  title?: string;
  restaurantImageUrl?: string;
  hidden?: boolean;
}

const LunchPlanList = ({ title, restaurantImageUrl, hidden }: ILunchPlanListProps) => {
  const { restaurantId } = useParams();
  const { status: lunchPlansStatus, data: lunchPlans } = useLunchPlansQuery({ restaurantId });
  const { data: user, status: userStatus } = useCurrentUserQuery();
  const isRestaurantPage = Boolean(restaurantId);

  if (lunchPlansStatus === STATUS_LOADING || userStatus === STATUS_LOADING) {
    return <AvatarLoader length={3} />;
  }

  if (lunchPlansStatus === STATUS_ERROR) {
    return (
      <p className='text-hiq-danger dark:text-hiq-danger-muted'>Something went wrong when loading lunch plans...</p>
    );
  }

  if (userStatus === STATUS_ERROR) {
    return (
      <p className='text-hiq-danger dark:text-hiq-danger-muted'>Something went wrong when loading user details...</p>
    );
  }

  if (!lunchPlans || !user) {
    return null;
  }

  return (
    <div
      className={classNames(
        'overflow-x-auto pt-3 transition-max-height duration-500',
        { 'my-0 max-h-0': hidden },
        { 'max-h-48': !hidden }
      )}
    >
      {title && <h3 className='text-md pl-4 font-semibold'>{title}</h3>}
      <ul
        className={classNames('mx-1.5 my-3 flex overflow-y-auto scrollbar-hide')}
        aria-label='Lunch plans'
        aria-hidden={hidden}
      >
        <UserPlansListItem
          activeLunchPlans={lunchPlans.filter((plan) => isActiveLunchPlan(plan, user.id))}
          isRestaurantPage={isRestaurantPage}
          restaurantImageUrl={restaurantImageUrl}
          userId={user.id}
        />

        {lunchPlans.map((lunchPlan) => (
          <LunchPlanListItem key={lunchPlan.id} lunchPlan={lunchPlan} isRestaurantPage={isRestaurantPage} />
        ))}
      </ul>
    </div>
  );
};

export default LunchPlanList;
