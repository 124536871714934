import neutralSmileyIconUrl from 'icons/smiley-neutral.png';

const NoContentYet = ({ type }: { type: string }) => {
  return (
    <div className='flex h-64 flex-col items-center justify-center rounded-lg bg-white dark:bg-hiq-black'>
      <img src={neutralSmileyIconUrl} alt='Neutral smiley' />
      <p>{`No ${type} yet :(`}</p>
    </div>
  );
};

export default NoContentYet;
