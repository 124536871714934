import { debounce } from 'lodash';
import { ChangeEvent, MutableRefObject, useCallback, useState } from 'react';
import TagSuggestion from 'components/TagSuggestion';
import { useTagsQuery } from 'hooks/useTagsQuery';
import { ITag } from 'types/ITag';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import Tag from 'components/Tag';

const AddTags = ({
  onSearch,
  searchString,
  addTagInputRef,
  onSelect,
  selectedTags,
  onRemove,
}: {
  onSearch: (value: string) => void;
  searchString: string;
  addTagInputRef: MutableRefObject<HTMLInputElement | null>;
  onSelect: (tag: string) => void;
  selectedTags: ITag[];
  onRemove?: (tag: string) => void;
}) => {
  const { data: tags } = useTagsQuery(searchString);
  const [showAddTagButton, setShowAddTagButton] = useState(false);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onSearch(value);
    },
    [onSearch]
  );

  const onTagSelect = (tag: string) => {
    if (tag) {
      onSelect(tag.toLowerCase());
      setShowAddTagButton(false);
      clearSearch();
    }
  };

  const clearSearch = useCallback(() => {
    if (addTagInputRef.current) {
      // Since this input field is using debounce it doesn't work well
      // for it to be a "controlled input", i.e. sending in the
      // searchQuery as a prop and using value={searchQuery}. So
      // we are clearing the value with a ref instead.
      addTagInputRef.current.value = '';
      onSearch('');
    }
  }, [onSearch, addTagInputRef]);

  const debouncedSearch = debounce(handleSearch, 500);

  const handleAddTagClick = () => {
    if (showAddTagButton) {
      if (addTagInputRef.current) {
        onTagSelect(addTagInputRef.current.value);
      }
    } else {
      setShowAddTagButton(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.code === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      if (addTagInputRef.current) {
        onTagSelect(addTagInputRef.current.value);
      }
    }
  };

  return (
    <div>
      <button type='button' className='flex items-center text-sm' aria-label='Add tag' onClick={handleAddTagClick}>
        <PlusIcon height={16} width={16} className='fill-hiq-black dark:fill-hiq-white' />
        Add tag
      </button>
      <ul className='mt-3 flex w-full flex-wrap break-words'>
        {showAddTagButton && (
          <input
            type='text'
            className='mr-3 mt-1.5 h-7 w-18 rounded-full bg-hiq-white px-3 py-1.5 text-center text-xs font-semibold placeholder-hiq-grey dark:text-hiq-black'
            ref={addTagInputRef}
            placeholder={'add tag'}
            id='addTagInput'
            aria-label='add tag'
            onChange={debouncedSearch}
            onKeyDown={handleKeyDown}
            maxLength={35}
          ></input>
        )}

        {selectedTags.map(({ name }) => (
          <Tag key={name} text={name} isSelected={true} onRemove={onRemove} />
        ))}
      </ul>

      <div className='flex w-4/5 w-full flex-wrap py-4'>
        {tags && tags.length > 0 && searchString && (
          <ul className='break-words rounded-lg px-2 py-4 shadow-m'>
            {tags.map(({ name }) => (
              <TagSuggestion
                key={name}
                text={name.toLowerCase()}
                onSelect={() => {
                  onTagSelect(name);
                }}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddTags;
