import NavHeader from 'components/NavHeader';
import AvatarLoader from 'components/loaders/AvatarLoader';
import TagsLoader from 'components/loaders/TagsLoader';

const RestaurantLoader = () => {
  return (
    <div role='alert' className='flex min-h-screen flex-col bg-hiq-light-pink dark:bg-black'>
      <NavHeader />
      <div className='animate-pulse'>
        <div className='ml-6 mt-5 h-6 w-2/4 rounded-md bg-white dark:bg-hiq-black'></div>
        <AvatarLoader length={3} />
        <div className='mt-12'></div>
        <div className='ml-6 mt-2 h-4 w-1/4 rounded-md bg-white dark:bg-hiq-black'></div>
        <div className='ml-6 mt-2 h-4 w-1/4 rounded-md bg-white dark:bg-hiq-black'></div>
        <div className='ml-6 mt-2 h-4 w-1/4 rounded-md bg-white dark:bg-hiq-black'></div>
        <div className='mt-2'></div>
        <TagsLoader length={3} />
        <div className='mx-6 mt-18 h-64 rounded-md bg-white dark:bg-hiq-black'></div>
      </div>
    </div>
  );
};

export default RestaurantLoader;
