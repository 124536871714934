import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface IModalHeaderProps {
  title: string;
  subtitle?: string;
}

const ModalHeader = ({ title, subtitle }: IModalHeaderProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='mb-3 mt-6.5 flex w-full flex-col items-center justify-center font-semibold'>
        <h1 className='text-xl'>{title}</h1>
        <h2 className='text-base'>{subtitle}</h2>
      </div>
      <div className='absolute right-0'>
        <button type='button' aria-label='Close' className='p-3.5' onClick={() => navigate('./..')}>
          <CloseIcon className='fill-hiq-black dark:fill-hiq-white' />
        </button>
      </div>
    </>
  );
};

export default ModalHeader;
