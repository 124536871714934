import ModalHeader from 'components/ModalHeader';
import PageError from 'components/PageError';
import FormLoader from 'components/loaders/FormLoader';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants/react-query-statuses';
import usePageTitle from 'hooks/usePageTitle';
import { useRestaurantQuery } from 'hooks/useRestaurantQuery';
import { useParams } from 'react-router-dom';
import EditRestaurant from 'pages/EditRestaurant';

const EditRestaurantLoader = () => {
  usePageTitle('LunchQ | Edit Restaurant');
  const { restaurantId } = useParams();
  const {
    status: restaurantStatus,
    data: restaurant,
    refetch: refetchRestaurant,
  } = useRestaurantQuery(Number(restaurantId));

  if (restaurantStatus === STATUS_ERROR) {
    return <PageError description='There was an issue when trying to load restaurant' onRefresh={refetchRestaurant} />;
  }
  if (restaurantStatus === STATUS_LOADING) {
    return (
      <div className='flex min-h-screen w-full flex-col text-hiq-black dark:bg-black dark:text-hiq-white'>
        <ModalHeader title='Edit restaurant' />
        <FormLoader />
      </div>
    );
  }
  if (restaurantStatus === STATUS_SUCCESS && restaurant) {
    return <EditRestaurant restaurant={restaurant} />;
  }
  return null;
};

export default EditRestaurantLoader;
