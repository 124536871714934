import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_REVIEW_QUESTIONS } from 'constants/query-keys';
import { IReviewQuestion } from 'types/IReviewQuestion';

export function useReviewsQuery(enabled: boolean = true, previousQuestionId?: string) {
  const params = new URLSearchParams();
  const questionType = 'FREETEXT';
  params.append('questionType', questionType);
  if (previousQuestionId) {
    params.append('previousQuestionId', previousQuestionId);
  }

  const query = useQuery<IReviewQuestion>({
    queryKey: [QUERY_KEY_REVIEW_QUESTIONS, { questionType, previousQuestionId }],
    retry: false,
    queryFn: () => makeFetchRequest(`reviews/questions?${params.toString()}`),
    enabled,
  });
  return { ...query };
}
