import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useSnackBar from 'hooks/useSnackBar';
import { makeFetchRequest } from 'services/api';
import { ELunchPlanType } from 'types/ELunchPlanType';
import { ERestMethod } from 'types/ERestMethod';
import { IJoinPlanResponse } from 'types/IJoinPlanResponse';

export function useCreatePlanMutation() {
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const mutation = async (data: { restaurantId: number; departure: string; lunchPlanType: ELunchPlanType }) => {
    return await makeFetchRequest('plans', ERestMethod.Post, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (response: IJoinPlanResponse) => {
      const newPlan = response.addedToPlan;
      snackBar.setResourceLink(`plans/${newPlan.id}`);
      navigate('./..');
      if ('addedToPlan' in response && response.removedFromPlan) {
        snackBar.setMessage(
          `Your lunch plan is visible for others to join! You have been removed from your previous lunch plan at ${response.removedFromPlan.restaurant.name}`
        );
      } else {
        snackBar.setMessage('Your lunch plan is visible for others to join!');
      }
    },
  });
}
