import classNames from 'classnames';
import NoContentYet from 'components/restaurant-view/NoContentYet';
import { IImage } from 'types/IImage';
import { ReactComponent as DotButton } from 'icons/image-dot-button.svg';
import EditImageDrawer from 'components/restaurant-view/EditImageDrawer';
import { useDeleteImageMutation } from 'hooks/useDeleteImageMutation';
import { useEditRestaurantMutation } from 'hooks/useEditRestaurantMutation';
import { useEffect, useState } from 'react';

interface IImageSliderProps {
  mainImage: IImage | null;
  images: IImage[];
  restaurantId: string | undefined;
  refetchRestaurant: () => void;
}

const ImageSlider = ({ mainImage, images, restaurantId, refetchRestaurant }: IImageSliderProps) => {
  const [editDrawer, setEditDrawer] = useState({ open: false, imageId: 0 });
  const [brokenImage, setBrokenImage] = useState(0);
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  useEffect(() => {
    if (brokenImage !== 0) {
      const interval = setInterval(() => {
        setBrokenImage(0);
        refetchRestaurant();
      }, 1000); // Reload every second until image is not broken

      return () => {
        clearInterval(interval);
      };
    }
  }, [brokenImage, refetchRestaurant]);

  const isCurrentImage = (imageId: number) => {
    return imageId === editDrawer.imageId;
  };

  const openDrawer = (imageId: number) => {
    setEditDrawer({ open: true, imageId });
  };

  const closeDrawer = () => {
    setEditDrawer({ open: false, imageId: 0 });
  };

  const editRestaurantMutation = useEditRestaurantMutation(Number(restaurantId), 'imageSlider', closeDrawer);
  const deleteImageMutation = useDeleteImageMutation(Number(restaurantId), closeDrawer);

  const handleSelectMainImage = (imageId: number) => {
    editRestaurantMutation.mutate({ mainImageId: imageId });
  };

  const handleDeleteImage = (imageId: number) => {
    deleteImageMutation.mutate(imageId);
  };

  const handleImageLoad = (imageId: number) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imageId]);
  };

  const handleBrokenImageError = (id: number) => {
    setBrokenImage(id);
  };

  if (images.length === 0) {
    return <NoContentYet type='images' />;
  }

  return (
    <>
      <ul className='flex flex-row space-x-2 overflow-x-auto' aria-label='List of images'>
        {images.map(({ id: imageId, paths }) => {
          const isImageLoaded = loadedImages.includes(imageId);

          return (
            <li
              key={paths.large}
              className={classNames('relative rounded-lg', {
                'border-2 border-hiq-pink dark:border-hiq-muted-pink': editDrawer.open && isCurrentImage(imageId),
              })}
            >
              {isImageLoaded && (
                <button
                  aria-label='Open edit photo'
                  onClick={() => openDrawer(imageId)}
                  className='absolute right-2 top-2'
                >
                  <DotButton
                    className={classNames('fill-hiq-black', {
                      'fill-hiq-pink': editDrawer.open && isCurrentImage(imageId),
                    })}
                  />
                </button>
              )}
              {brokenImage === imageId ? (
                <div className='bg-pink-10 h-64 w-72 max-w-none animate-pulse rounded-lg dark:bg-hiq-black' />
              ) : (
                <div className='h-64 w-72 max-w-none rounded-lg bg-pink-100  dark:bg-hiq-black'>
                  {isImageLoaded ? (
                    <img
                      alt=''
                      src={paths.large}
                      className='inline-block h-64 w-72 max-w-none rounded-lg object-cover'
                      onError={() => handleBrokenImageError(imageId)}
                    />
                  ) : (
                    <img
                      alt=''
                      src={paths.large}
                      className='hidden h-64 w-72 max-w-none animate-pulse rounded-lg bg-pink-100'
                      onLoad={() => handleImageLoad(imageId)}
                      onError={() => handleBrokenImageError(imageId)}
                    />
                  )}
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <EditImageDrawer
        open={editDrawer.open}
        imageId={editDrawer.imageId}
        closeDrawer={closeDrawer}
        handleSelectMainImage={handleSelectMainImage}
        handleDeleteImage={handleDeleteImage}
        isLoading={editRestaurantMutation.isLoading || deleteImageMutation.isLoading}
        isMainImage={mainImage?.id === editDrawer.imageId}
      />
    </>
  );
};

export default ImageSlider;
