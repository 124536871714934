import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from 'providers/AuthProvider';
import { loginRequest } from 'authConfig';
import { ERestMethod } from 'types/ERestMethod';

export async function handleError(res: Response) {
  if (!res.ok) {
    const errorBody = res.headers.get('content-type')?.includes('application/json')
      ? await res.json()
      : await res.text();

    throw new Error({ status: res.status, ...errorBody });
  }

  return res;
}

export const getActiveAccount = () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  return activeAccount || accounts[0];
};

const acquireAccessToken = async () => {
  const activeAccount = getActiveAccount();

  if (!activeAccount) {
    throw new Error('User does not seem to be logged in');
  }

  const request = {
    scopes: loginRequest.scopes,
    account: activeAccount,
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(request);
    }

    throw error;
  }
};

export async function makeFetchRequest(path: string, method: ERestMethod = ERestMethod.Get, data?: object) {
  const accessToken = await acquireAccessToken();
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${path}`, {
    method,
    headers: { Authorization: `Bearer ${accessToken}`, 'content-type': data ? 'application/json' : 'text/plain' },
    body: JSON.stringify(data),
  });
  await handleError(response);

  try {
    return await response.json();
  } catch {
    return null;
  }
}

export async function postImage(path: string, formData: FormData) {
  const accessToken = await acquireAccessToken();
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${path}`, {
    method: ERestMethod.Post,
    headers: { Authorization: `Bearer ${accessToken}` },
    body: formData,
  });
  await handleError(response);
  return await response.json();
}
