import { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';

interface IDarkModeContext {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

export const DarkModeContext = createContext<IDarkModeContext>({
  darkMode: false,
  setDarkMode: () => {},
});

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};

export const isDarkModeEnabled = () => {
  if (localStorage.getItem('darkMode') !== null) {
    return localStorage.getItem('darkMode') === 'true';
  } else {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
};

export const DarkModeProvider = ({ children }: PropsWithChildren) => {
  const [darkMode, setDarkMode] = useState(isDarkModeEnabled);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', `${darkMode}`);
  }, [darkMode]);

  return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>{children}</DarkModeContext.Provider>;
};
