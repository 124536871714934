import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import AddPlanForm from 'components/AddPlanForm';
import { useCreatePlanMutation } from 'hooks/useCreatePlanMutation';
import usePageTitle from 'hooks/usePageTitle';
import { ELunchPlanType } from 'types/ELunchPlanType';

export interface IAddPlanFormValues {
  restaurantId: number | undefined;
  departure: string;
  lunchPlanType: ELunchPlanType | undefined;
}

const AddPlan = () => {
  usePageTitle('LunchQ | Add Lunch Plan');
  const { restaurantId: restaurantIdParam } = useParams();
  const createPlanMutation = useCreatePlanMutation();

  const initialValues: IAddPlanFormValues = {
    restaurantId: restaurantIdParam ? Number(restaurantIdParam) : undefined,
    departure: new Date().toISOString(),
    lunchPlanType: undefined,
  };

  const validate = ({ lunchPlanType, restaurantId }: IAddPlanFormValues) => {
    let errors = {};

    if (lunchPlanType === undefined) {
      errors = { ...errors, lunchPlanType: 'Required' };
    }

    if (restaurantId === undefined) {
      errors = { ...errors, restaurantId: 'Required' };
    }

    return errors;
  };

  const submitAddPlan = (values: IAddPlanFormValues) => {
    invariant(values.lunchPlanType);
    invariant(values.restaurantId);

    createPlanMutation.mutate({
      departure: dayjs(values.departure).format('YYYY-MM-DD'),
      lunchPlanType: values.lunchPlanType,
      restaurantId: values.restaurantId,
    });
  };

  return (
    <Formik onSubmit={submitAddPlan} initialValues={initialValues} validate={validate}>
      {(formProps) => (
        <AddPlanForm
          allowChangeRestaurant={!restaurantIdParam}
          isLoading={createPlanMutation.isLoading}
          {...formProps}
        />
      )}
    </Formik>
  );
};

export default AddPlan;
