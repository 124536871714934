import { Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddTags from 'components/AddTags';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { IRestaurantFormValues } from 'types/IRestaurantFormValues';
import { ITag } from 'types/ITag';
import { ReactComponent as DirectionsWalkIcon } from 'icons/directions-walk.svg';
import { ReactComponent as LocationIcon } from 'icons/location.svg';
import { ReactComponent as WebpageIcon } from 'icons/website.svg';

interface IRestaurantFormProps {
  initialValues: IRestaurantFormValues;
  onSubmit: (values: IRestaurantFormValues) => void;
  isLoading: boolean;
  submitButtonText: string;
}

const RestaurantForm = ({ initialValues, onSubmit, isLoading, submitButtonText }: IRestaurantFormProps) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [selectedTags, setSelectedTags] = useState<ITag[]>(initialValues.tags);
  const onSearch = (value: string) => setSearchString(value);
  const addTagInputRef = useRef<HTMLInputElement>(null);

  const validate = ({ name, distanceMinutes }: IRestaurantFormValues) => {
    let errors = {};
    if (!name) {
      errors = { ...errors, name: 'The restaurant must have a name' };
    }

    const parsedDistanceMinutes = Number(distanceMinutes);
    if (isNaN(parsedDistanceMinutes)) {
      errors = { ...errors, distanceMinutes: 'Walking time must be a number' };
    } else if (parsedDistanceMinutes <= 0) {
      errors = { ...errors, distanceMinutes: 'Walking time must be greater than 0' };
    }
    return errors;
  };

  const onTagSelect = (tag: string) => {
    if (selectedTags.some((oldTag) => oldTag.name.toLowerCase() === tag.toLowerCase())) {
      console.log('Already added');
    } else {
      setSelectedTags((prevState) => [...prevState, { name: tag }]);
    }
  };

  const onTagRemove = (tagToRemove: string) => {
    setSelectedTags((prevState) => prevState.filter((tag) => tag.name !== tagToRemove));
  };

  return (
    <Formik
      onSubmit={(formValues) => onSubmit({ ...formValues, tags: selectedTags })}
      initialValues={initialValues}
      validate={validate}
    >
      {({ handleSubmit, handleChange, errors, values }: FormikProps<IRestaurantFormValues>) => {
        return (
          <form className='flex w-full grow flex-col' onSubmit={handleSubmit}>
            <TextInput
              id='name'
              value={values.name}
              className='mt-7'
              onChange={handleChange}
              label='Name'
              error={errors.name}
              maxLength={255}
            />
            <TextInput
              id='address'
              value={values.address}
              className='mt-7'
              onChange={handleChange}
              label='Address'
              error={errors.address}
              rightIcon={<LocationIcon width='22' height='22' className='fill-hiq-black dark:fill-hiq-white' />}
              maxLength={255}
            />
            <TextInput
              id='distanceMinutes'
              value={values.distanceMinutes.toString()}
              className='mt-7'
              onChange={handleChange}
              label='Walking time to/from office (min)'
              error={errors.distanceMinutes}
              rightIcon={<DirectionsWalkIcon width='22' height='22' className='fill-hiq-black dark:fill-hiq-white' />}
              maxLength={5}
            />
            <TextInput
              id='webpage'
              value={values.webpage}
              className='mt-7'
              onChange={handleChange}
              label='Webpage'
              error={errors.webpage}
              rightIcon={<WebpageIcon width='22' height='22' className='fill-hiq-black dark:fill-hiq-white' />}
              maxLength={255}
            />
            <div className='flex grow p-4'>
              <AddTags
                onSearch={onSearch}
                searchString={searchString}
                addTagInputRef={addTagInputRef}
                onSelect={onTagSelect}
                onRemove={onTagRemove}
                selectedTags={selectedTags}
              />
            </div>
            <div className='mb-5 flex w-full flex-col'>
              <Button
                type='submit'
                text={submitButtonText}
                styleType='primary'
                disabled={isLoading}
                isLoading={isLoading}
              />
              <Button
                type='button'
                text='Cancel'
                styleType='secondary'
                disabled={false}
                isLoading={false}
                onClick={() => navigate('./..')}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default RestaurantForm;
