import AlertMessage from 'components/AlertMessage';
import hiqSkullUrl from 'icons/hiq-skull.png';

interface IPageErrorProps {
  title?: string;
  description: string;
  className?: string;
  onRefresh?: () => void;
}

const PageError = ({ description, className = 'mt-40', onRefresh }: IPageErrorProps) => (
  <AlertMessage
    title='Something went wrong :('
    icon={hiqSkullUrl}
    iconAltText='Skull emoji'
    description={description}
    className={className}
    onRefresh={onRefresh}
  />
);

export default PageError;
