import { ILunchPlan } from 'types/ILunchPlan';

interface IPlanIndicatorProps {
  plans: ILunchPlan[];
  currentPlanIndex: number;
}

const PlanIndicators = ({ plans, currentPlanIndex }: IPlanIndicatorProps) => (
  <div className='mr-1 flex px-2 pb-1 pt-4'>
    {plans.map(({ id }, index) => (
      <div
        key={id}
        data-testid='plan-indicator'
        className={`ml-1 h-0.75 w-full rounded-md ${
          index <= currentPlanIndex ? 'bg-hiq-black dark:bg-hiq-white' : 'bg-white dark:bg-hiq-grey'
        }`}
      />
    ))}
  </div>
);

export default PlanIndicators;
