import { Link } from 'react-router-dom';
import Tag from 'components/Tag';
import { isToday } from 'utils';
import { ILunchPlan } from 'types/ILunchPlan';
import { ITag } from 'types/ITag';
import { ReactComponent as RobotIcon } from 'icons/robot.svg';
import { ReactComponent as UnicornIcon } from 'icons/unicorn.svg';
import { ReactComponent as ArrowIcon } from 'icons/right-arrow.svg';

interface IPlanNavigatorProps {
  tags: ITag[];
  imageUrl: string;
  departure: string;
  previousPlan?: ILunchPlan;
  nextPlan?: ILunchPlan;
  userId: string | undefined;
}

const PlanNavigator = ({ tags, imageUrl, departure, previousPlan, nextPlan, userId }: IPlanNavigatorProps) => (
  <nav className='relative flex min-h-62.5 w-full grow flex-col'>
    {imageUrl ? (
      <div
        role='img'
        aria-label='Restaurant'
        className='grow bg-center bg-no-repeat'
        style={{ backgroundImage: `url('${imageUrl}')`, backgroundSize: '100% auto' }}
      />
    ) : (
      <div className='flex h-3/4 items-center justify-center'>
        {isToday(departure) ? (
          <UnicornIcon role='img' aria-label='Pink unicorn' />
        ) : (
          <RobotIcon role='img' aria-label='Blue robot' />
        )}
      </div>
    )}
    {previousPlan && (
      <Link
        aria-label='Previous plan'
        className='absolute left-0 top-0 z-10 flex h-full w-6/12 items-center'
        to={`./../../plans/${previousPlan.id}`}
        state={{ userId }}
        replace
      >
        <ArrowIcon className='ml-1.5 h-9 w-7 rotate-180 bg-white/50 fill-black' />
      </Link>
    )}
    {nextPlan && (
      <Link
        aria-label='Next plan'
        className='absolute right-0 top-0 z-10 flex h-full w-6/12 flex-row-reverse items-center'
        to={`./../../plans/${nextPlan.id}`}
        state={{ userId }}
        replace
      >
        <ArrowIcon className='mr-1.5 h-9 w-7 bg-white/50 fill-black' />
      </Link>
    )}
    <ul className='absolute bottom-5 w-full break-words px-3'>
      {tags.map(({ name }) => (
        <Tag key={name} text={name} />
      ))}
    </ul>
  </nav>
);

export default PlanNavigator;
