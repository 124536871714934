import { NO_DISTANCE_MINUTES } from 'constants/no-info-paragraphs';
import { ReactComponent as DirectionsWalkIcon } from 'icons/directions-walk.svg';

const DistanceParagraph = ({ distanceMinutes }: { distanceMinutes: number }) => {
  return (
    <p className='flex items-center text-sm'>
      <DirectionsWalkIcon />
      <span className='ml-1'>{distanceMinutes ? `${distanceMinutes} min` : NO_DISTANCE_MINUTES}</span>
    </p>
  );
};

export default DistanceParagraph;
