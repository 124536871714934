import classNames from 'classnames';
import { ReactComponent as DoneIcon } from 'icons/done-check.svg';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';

interface IJoinPlanButtonProps {
  title: string;
  description: string;
  ariaLabel: string;
  iconUrl: string;
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
  hasJoined: boolean;
}

const JoinPlanButton = ({
  title,
  description,
  ariaLabel,
  iconUrl,
  onClick,
  disabled,
  isLoading,
  hasJoined,
}: IJoinPlanButtonProps) => (
  <button
    type='button'
    aria-label={ariaLabel}
    disabled={disabled || isLoading}
    className={classNames(
      'm-3 flex items-center justify-between rounded-lg bg-white p-5 enabled:active:bg-hiq-white dark:bg-hiq-black',
      {
        'border border-hiq-black dark:border-hiq-muted-pink': hasJoined,
      }
    )}
    onClick={onClick}
  >
    <div className='flex items-center'>
      <img alt='' src={iconUrl} className='ml-2 h-8 w-8' />
      <div className='ml-6 flex flex-col items-start justify-center'>
        <h4 className='font-medium'>{title}</h4>
        <p className='text-xs font-light'>{description}</p>
      </div>
    </div>
    {isLoading ? (
      <LoadingSpinnerIcon role='alert' className='mr-3 h-5 w-5 animate-spin' />
    ) : (
      <p className='mr-4 text-xs font-medium'>
        {hasJoined ? (
          <span className='flex items-center'>
            <DoneIcon className='mr-1 dark:fill-hiq-white' /> You're going!
          </span>
        ) : (
          'Join'
        )}
      </p>
    )}
  </button>
);

export default JoinPlanButton;
