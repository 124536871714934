import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_CURRENT_USER } from 'constants/query-keys';
import { ILunchQUser } from 'types/ILunchQUser';

export function useCurrentUserQuery(enabled: boolean = true) {
  return useQuery<ILunchQUser>({
    queryKey: [QUERY_KEY_CURRENT_USER],
    retry: false,
    queryFn: () => makeFetchRequest('users/me'),
    enabled,
  });
}
