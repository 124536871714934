import AddPlanButton from 'components/AddPlanButton';
import ListItemWrapper from 'components/ListItemWrapper';
import RestaurantAvatar from 'components/RestaurantAvatar';
import UserAvatar from 'components/UserAvatar';
import { getRestaurantMainImage, isToday } from 'utils';
import { EImageSize } from 'types/EImageSize';
import { ILunchPlan } from 'types/ILunchPlan';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface IUserPlansListItemProps {
  activeLunchPlans: ILunchPlan[];
  isRestaurantPage: boolean;
  restaurantImageUrl: string | undefined;
  userId: string;
}

const UserPlansListItem = ({
  activeLunchPlans,
  isRestaurantPage,
  restaurantImageUrl,
  userId,
}: IUserPlansListItemProps) => {
  if (activeLunchPlans.length) {
    const nextPlan = activeLunchPlans[0];
    const plannedForToday = isToday(nextPlan.departure);
    const header = `Your plans (${activeLunchPlans.length})`;
    const link = `plans/${nextPlan.id}`;

    return (
      <ListItemWrapper header={isRestaurantPage ? 'Add a plan' : header}>
        {isRestaurantPage ? (
          <RestaurantAvatar
            imageUrl={getRestaurantMainImage(nextPlan.restaurant.mainImage, EImageSize.SMALL)}
            link={link}
            plannedForLater={!plannedForToday}
            plannedForToday={plannedForToday}
            userId={userId}
          />
        ) : (
          <Link
            to={link}
            state={{ userId }}
            className={classNames(
              'max-h-20.5 flex min-h-20.5 min-w-20.5 max-w-20.5 items-center justify-center rounded-full',
              {
                'bg-gradient-border-today': plannedForToday,
                'bg-gradient-border-tomorrow': !plannedForToday,
              }
            )}
          >
            <UserAvatar />
          </Link>
        )}

        <AddPlanButton />
      </ListItemWrapper>
    );
  } else {
    return (
      <ListItemWrapper
        header={isRestaurantPage ? 'Add a plan' : 'Your plans (0)'}
        subheader={isRestaurantPage ? undefined : 'Add a plan'}
      >
        {isRestaurantPage ? (
          <RestaurantAvatar imageUrl={restaurantImageUrl} plannedForLater={false} plannedForToday={false} />
        ) : (
          <UserAvatar />
        )}
        <AddPlanButton />
      </ListItemWrapper>
    );
  }
};

export default UserPlansListItem;
