import { Formik, FormikProps } from 'formik';
import Button from 'components/Button';
import { IReviewFormValues } from 'types/IReviewFormValues';
import MultiLineTextInput from 'components/MultiLineTextInput';
import Drawer from 'react-modern-drawer';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface IReviewFormProps {
  initialValues: IReviewFormValues;
  onSubmit: (values: IReviewFormValues) => void;
  isLoading: boolean;
  isOpen: boolean;
  onRandomizeQuestion: () => void;
  handleCloseDrawer: () => void;
  restaurantName: string;
}

const ReviewForm = ({
  initialValues,
  onSubmit,
  isLoading,
  isOpen,
  onRandomizeQuestion,
  handleCloseDrawer,
  restaurantName,
}: IReviewFormProps) => {
  const validate = ({ answer }: IReviewFormValues) => {
    let errors = {};

    if (!answer) {
      errors = { ...errors, answer: 'Write an answer in order to save it.' };
    }
    return errors;
  };

  return (
    <Drawer open={isOpen} direction='bottom' lockBackgroundScroll={true} style={{ height: '100%' }}>
      <div className='flex h-full flex-col justify-between dark:bg-hiq-black'>
        <div className='h-auto overflow-y-auto p-4'>
          <div className='relative flex items-center justify-center'>
            <h2 className='text-xl font-semibold'>Answer a question</h2>
            <button aria-label='Close participants view' className='absolute right-1' onClick={handleCloseDrawer}>
              <CloseIcon className='fill-hiq-black dark:fill-hiq-white' />
            </button>
          </div>
          <h3 className='mb-2 text-center text-base font-semibold'>{restaurantName}</h3>
        </div>
        <div>
          <h3 className='p-4 text-2xl font-semibold'>{initialValues.question}</h3>
          {isOpen && (
            <Formik
              enableReinitialize
              onSubmit={(formValues, { resetForm }) => {
                onSubmit({ ...formValues });
                resetForm();
              }}
              initialValues={initialValues}
              validate={validate}
            >
              {({ handleSubmit, handleChange, errors, values }: FormikProps<IReviewFormValues>) => {
                return (
                  <form className='flex w-full grow flex-col' onSubmit={handleSubmit}>
                    <MultiLineTextInput
                      placeholder='Add your answer...'
                      id='answer'
                      value={values.answer}
                      onChange={handleChange}
                      error={errors.answer}
                      maxLength={200}
                    />
                    <div className='my-5 flex w-full flex-col'>
                      <Button
                        type='submit'
                        text='Save your answer'
                        styleType='primary'
                        disabled={isLoading}
                        isLoading={isLoading}
                      />
                      <Button
                        type='button'
                        text='Randomize new question'
                        styleType='secondary'
                        disabled={false}
                        isLoading={false}
                        onClick={onRandomizeQuestion}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ReviewForm;
