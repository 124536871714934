import React, { useContext } from 'react';
import AddRestaurantCard from 'components/AddRestaurantCard';
import AlertMessage from 'components/AlertMessage';
import PageError from 'components/PageError';
import RestaurantCard from 'components/RestaurantCard';
import SortButton from 'components/SortButton';
import RestaurantListLoader from 'components/loaders/RestaurantListLoader';
import { useRestaurantsQuery } from 'hooks/useRestaurantsQuery';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants/react-query-statuses';
import smileyThinking from 'icons/smiley-thinking.png';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { SearchContext } from 'providers/SearchProvider';

interface IRestaurantListProps {
  title?: string;
}

const RestaurantList = ({ title }: IRestaurantListProps) => {
  const { sortOption, searchQuery, distanceMinutesEnd, selectedTags } = useContext(SearchContext);
  const {
    status,
    data: restaurants,
    refetch,
  } = useRestaurantsQuery({ searchQuery, distanceMinutesEnd, selectedTags, activeSortOption: sortOption });
  const { data: user } = useCurrentUserQuery();

  return (
    <div className='flex flex-col p-4'>
      <div className='flex justify-between'>
        <h3 className='text-md font-semibold'>{title}</h3>
        <SortButton />
      </div>
      <ul aria-label='Restaurants'>
        {status === STATUS_LOADING && <RestaurantListLoader length={3} />}

        {status === STATUS_SUCCESS && restaurants.length === 0 && (
          <>
            <AlertMessage
              title={searchQuery ? 'No restaurants matched your search :(' : 'No restaurants were found'}
              description={
                searchQuery
                  ? 'Try changing the search terms or removing the filters.'
                  : 'Create the first restaurant using the button below'
              }
              icon={smileyThinking}
              iconAltText='Thinking face emoji'
              className='mt-7'
            />

            <AddRestaurantCard className='mt-16' />
          </>
        )}

        {status === STATUS_ERROR && (
          <PageError
            className='mt-10'
            description='Something went wrong when trying to load restaurants. Click the refresh button to retry'
            onRefresh={refetch}
          />
        )}
        {status === STATUS_SUCCESS &&
          restaurants.length > 0 &&
          restaurants.map((restaurant, index) => {
            return (
              <React.Fragment key={restaurant.id}>
                <RestaurantCard key={`${restaurant.id}${restaurant.name}`} restaurant={restaurant} user={user} />
                {index === (restaurants.length > 2 ? 1 : restaurants.length - 1) && <AddRestaurantCard />}
              </React.Fragment>
            );
          })}
      </ul>
    </div>
  );
};

export default RestaurantList;
