import { useMutation } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { ERestMethod } from 'types/ERestMethod';
import { IReviewEditFormValues } from 'types/IReviewFormValues';
import useSnackBar from './useSnackBar';

export function useEditReviewMutation(reviewId: number, onSuccessCallback: () => void) {
  const snackBar = useSnackBar();

  const mutation = async (data: IReviewEditFormValues) => {
    return await makeFetchRequest(`reviews/${reviewId}`, ERestMethod.Put, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      snackBar.setMessage(`Review has been edited successfully.`);
      onSuccessCallback();
    },
  });
}
