import { useNavigate } from 'react-router-dom';
import ModalHeader from 'components/ModalHeader';
import { ReactComponent as TrashBinIcon } from 'icons/trash-bin.svg';
import RestaurantForm from 'components/RestaurantForm';
import { useEditRestaurantMutation } from 'hooks/useEditRestaurantMutation';
import { IRestaurantFormValues } from 'types/IRestaurantFormValues';
import { useState } from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { useDeleteRestaurantMutation } from 'hooks/useDeleteRestaurantMutation';
import { IRestaurant } from 'types/IRestaurant';

interface IEditRestaurantProps {
  restaurant: IRestaurant;
}

const EditRestaurant = ({ restaurant }: IEditRestaurantProps) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editRestaurantMutation = useEditRestaurantMutation(restaurant.id, 'editRestaurant', () => navigate('./..'));
  const deleteRestaurantMutation = useDeleteRestaurantMutation(restaurant.id, restaurant.name);

  const onSubmit = (values: IRestaurantFormValues) => {
    editRestaurantMutation.mutate({
      name: values.name,
      address: values.address,
      distanceMinutes: Number(values.distanceMinutes),
      webpage: values.webpage,
      tags: values.tags,
    });
  };

  return (
    <div className='flex min-h-screen w-full flex-col text-hiq-black dark:bg-hiq-black dark:text-hiq-white'>
      <button
        type='button'
        aria-label='Delete restaurant'
        onClick={() => setShowDeleteModal(true)}
        className='absolute left-0 p-4.5'
      >
        <TrashBinIcon className='fill-hiq-black dark:fill-hiq-white' />
      </button>
      <ModalHeader title='Edit restaurant' />
      <RestaurantForm
        initialValues={{
          name: restaurant.name,
          address: restaurant.address,
          distanceMinutes: restaurant.distanceMinutes,
          webpage: restaurant.webpage,
          tags: restaurant.tags,
        }}
        onSubmit={onSubmit}
        isLoading={editRestaurantMutation.isLoading}
        submitButtonText='Save'
      />
      {showDeleteModal && (
        <ConfirmationModal
          title='Sure you want to delete?'
          description={restaurant.name}
          acceptFn={() => deleteRestaurantMutation.mutate()}
          isLoading={deleteRestaurantMutation.isLoading}
          acceptText='Yes, delete restaurant'
          abortText='Cancel'
          closeModal={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default EditRestaurant;
