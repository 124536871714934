const AvatarLoader = ({ length }: { length: number }) => {
  return (
    <div className='flex space-x-2 overflow-y-auto p-4' role='alert' aria-busy='true' aria-live='polite'>
      {Array.from({ length }, (_, index) => (
        <div key={index} className='h-20 w-20 animate-pulse rounded-full bg-pink-100 dark:bg-hiq-grey' />
      ))}
    </div>
  );
};

export default AvatarLoader;
