import { useRef } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import Button from 'components/Button';

interface IConfirmationModalProps {
  title: string;
  description: string;
  acceptFn: () => void;
  isLoading: boolean;
  acceptText: string;
  abortText: string;
  closeModal: () => void;
}

const ConfirmationModal = ({
  title,
  description,
  acceptFn,
  isLoading,
  acceptText,
  abortText,
  closeModal,
}: IConfirmationModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, true, closeModal);

  return (
    <>
      <div className='absolute left-0 top-0 h-full w-full bg-black bg-opacity-25' />
      <div
        ref={modalRef}
        aria-modal='true'
        role='alertdialog'
        className='absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 justify-center'
      >
        <div className='flex w-11/12 max-w-md flex-col rounded-lg bg-white p-1.5 pb-6 dark:bg-hiq-grey'>
          <button type='button' aria-label='Close' className='self-end p-3' onClick={closeModal}>
            <CloseIcon className='dark:fill-hiq-white' />
          </button>
          <h2 className='mx-3 text-xl font-medium'>{title}</h2>
          <p className='mx-3 my-5'>{description}</p>
          <Button
            type='button'
            styleType='danger'
            text={acceptText}
            onClick={acceptFn}
            disabled={isLoading}
            isLoading={isLoading}
          />
          <Button
            type='button'
            styleType='secondary'
            text={abortText}
            onClick={closeModal}
            disabled={false}
            isLoading={false}
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
