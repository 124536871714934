import debounce from 'lodash/debounce';
import { SearchContext } from 'providers/SearchProvider';
import { ChangeEvent, useCallback, useContext, useEffect } from 'react';

const DistanceSlider = () => {
  const { distanceMinutesEnd, setDistanceMinutesEnd } = useContext(SearchContext);

  const debouncedSetDistanceMinutesEnd = debounce((value: string) => {
    setDistanceMinutesEnd(value);
  }, 500);

  const handleDistanceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDistanceMinutesEnd(value);
      debouncedSetDistanceMinutesEnd(value);
    },
    [setDistanceMinutesEnd, debouncedSetDistanceMinutesEnd]
  );

  const resetValue = useCallback(() => {
    setDistanceMinutesEnd('30');
    debouncedSetDistanceMinutesEnd.cancel();
  }, [setDistanceMinutesEnd, debouncedSetDistanceMinutesEnd]);

  useEffect(() => {
    if (!distanceMinutesEnd) {
      resetValue();
    }

    return () => {
      debouncedSetDistanceMinutesEnd.cancel();
    };
  }, [distanceMinutesEnd, resetValue, debouncedSetDistanceMinutesEnd]);

  return (
    <>
      <div className='mb-2 flex justify-between'>
        <label htmlFor='distanceRange'>Maximum distance from the office</label>
        <p>{distanceMinutesEnd === '30' ? '30+' : distanceMinutesEnd} min</p>
      </div>
      <input
        type='range'
        className='transparent h-1 w-full cursor-grab rounded-lg border-0 accent-hiq-pink focus:outline-none dark:accent-hiq-muted-pink'
        id='distanceRange'
        value={distanceMinutesEnd}
        onChange={handleDistanceChange}
        min='0'
        max='30'
        step='1'
      />
    </>
  );
};

export default DistanceSlider;
