const TagsLoader = ({ length }: { length: number }) => {
  return (
    <ul className='flex space-x-2 p-4' role='alert' aria-busy='true' aria-live='polite'>
      {Array.from({ length }, (_, index) => (
        <li
          key={index}
          className='mr-2 mt-1.5 inline-block h-7	w-18 animate-pulse rounded-full bg-pink-100 dark:bg-hiq-grey'
        />
      ))}
    </ul>
  );
};

export default TagsLoader;
