import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_PLANS, QUERY_KEY_RESTAURANT } from 'constants/query-keys';
import { ERestMethod } from 'types/ERestMethod';
import useSnackBar from './useSnackBar';

export function useDeleteImageMutation(restaurantId: number, onSuccessCallback?: () => void) {
  const snackBar = useSnackBar();

  const mutation = async (imageId: number) => {
    return await makeFetchRequest(`restaurants/${restaurantId}/images/${imageId}`, ERestMethod.Delete);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      snackBar.setMessage(`Image has been deleted successfully.`);
      queryClient.invalidateQueries([QUERY_KEY_RESTAURANT, { restaurantId }]);
      queryClient.invalidateQueries([QUERY_KEY_PLANS, { restaurantId: restaurantId.toString() }]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
}
