interface IToggleSwitchProps {
  isChecked: boolean;
  testId: string;
  onToggle: () => void;
}

const ToggleSwitch = ({ isChecked, testId, onToggle }: IToggleSwitchProps) => {
  return (
    <label className='relative inline-flex cursor-pointer items-center'>
      <input type='checkbox' className='peer sr-only' checked={isChecked} data-testid={testId} readOnly />
      <div
        onClick={onToggle}
        className="peer h-3 w-5 rounded-full border border-hiq-black after:absolute after:left-[2px] after:top-0.5 after:h-2 after:w-2 after:rounded-full after:bg-hiq-black after:transition-all after:content-[''] peer-checked:after:translate-x-full dark:border-0 dark:bg-hiq-white"
      />
    </label>
  );
};

export default ToggleSwitch;
