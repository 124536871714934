import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { PropsWithChildren } from 'react';
import { msalConfig } from 'authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export default function AuthProvider({ children }: PropsWithChildren) {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
