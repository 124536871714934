import { useNavigate } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';

interface ITitleWithBackButtonProps {
  title: string;
  linkTo: string;
}

const TitleWithBackButton = ({ title, linkTo }: ITitleWithBackButtonProps) => {
  const navigate = useNavigate();

  return (
    <>
      <button aria-label='Go back' onClick={() => navigate(linkTo)}>
        <RightArrowIcon className='rotate-180 fill-current' />
      </button>
      <h1 className='mr-auto truncate text-xl font-semibold'>{title}</h1>
    </>
  );
};

export default TitleWithBackButton;
