import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { useAddImageMutation } from 'hooks/useAddImageMutation';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import smileyCatIconUrl from 'icons/smiley-cat.png';
import useSnackBar from 'hooks/useSnackBar';

interface IAddImageCardProps {
  restaurantId: number;
}

const AddImageCard = ({ restaurantId }: IAddImageCardProps) => {
  const addImageMutation = useAddImageMutation(restaurantId);
  const snackBar = useSnackBar();

  const addImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && validateImage(event.target.files[0])) {
      let formData = new FormData();
      formData.append('image', event.target.files[0]);
      addImageMutation.mutate(formData);
    }
  };

  const validateImage = (image: File): boolean => {
    const maximumImageSize = 10240; // 10MB
    if (!image) {
      snackBar.setMessage('Please choose an image');
      return false;
    }

    const imageSizeKiloBytes = image.size / 1024;

    if (imageSizeKiloBytes > maximumImageSize) {
      snackBar.setMessage('Image size is greater than maximum limit of 10MB');
      return false;
    }

    return true;
  };

  return (
    <label
      htmlFor='restaurantImage'
      className={classNames(
        'mt-3 flex h-21 w-full items-center rounded-md bg-hiq-muted-tea pl-4 pr-3 dark:bg-hiq-black',
        {
          'active:bg-hiq-muted-green': !addImageMutation.isLoading,
          'hover:cursor-pointer': !addImageMutation.isLoading,
        }
      )}
    >
      <input
        id='restaurantImage'
        disabled={addImageMutation.isLoading}
        type='file'
        accept='image/jpg, image/png, image/jpeg'
        onChange={addImage}
        className='hidden'
      />

      {addImageMutation.isLoading ? (
        <div className='flex w-full items-center justify-center'>
          <LoadingSpinnerIcon role='alert' className='mr-3 h-5 w-5 animate-spin' />
        </div>
      ) : (
        <>
          <div className='flex min-w-20.5 justify-center'>
            <img src={smileyCatIconUrl} alt='' />
          </div>

          <div className='ml-2 text-left'>
            <h3 className='mb-1 text-sm font-semibold text-hiq-moss-green dark:text-hiq-white'>
              Have you been to this place?
            </h3>
            <p className='text-sm text-hiq-moss-green dark:text-hiq-muted-pink'>Add an image!</p>
          </div>

          <div className='ml-auto'>
            <PlusIcon className='fill-hiq-moss-green dark:fill-hiq-muted-pink' />
          </div>
        </>
      )}
    </label>
  );
};

export default AddImageCard;
