import { PropsWithChildren, createContext, useState } from 'react';
import { ESortOption } from 'types/ESortingOptions';

interface SearchContextProps {
  sortOption: ESortOption;
  searchQuery: string;
  distanceMinutesEnd: string;
  selectedTags: string[];
  setSortOption: (option: ESortOption) => void;
  setSearchQuery: (query: string) => void;
  setDistanceMinutesEnd: (query: string) => void;
  setSelectedTags: (query: string[]) => void;
}

export const SearchContext = createContext<SearchContextProps>({
  sortOption: ESortOption.Default,
  searchQuery: '',
  distanceMinutesEnd: '',
  selectedTags: [],
  setSortOption: () => {},
  setSearchQuery: () => {},
  setDistanceMinutesEnd: () => {},
  setSelectedTags: () => {},
});

const SearchProvider = ({ children }: PropsWithChildren) => {
  const [sortOption, setSortOption] = useState<ESortOption>(ESortOption.Default);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [distanceMinutesEnd, setDistanceMinutesEnd] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  return (
    <SearchContext.Provider
      value={{
        sortOption,
        searchQuery,
        distanceMinutesEnd,
        selectedTags,
        setSortOption,
        setSearchQuery,
        setDistanceMinutesEnd,
        setSelectedTags,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
