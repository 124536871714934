import { useRef } from 'react';
import Drawer from 'react-modern-drawer';
import useClickOutside from 'hooks/useClickOutside';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface IReviewOptionsDrawer {
  reviewId: number;
  isOpen: boolean;
  closeDrawer: () => void;
  openReviewForm: () => void;
  handleDeleteReview: (reviewId: number) => void;
}

const ReviewOptionsDrawer = ({
  reviewId,
  isOpen,
  closeDrawer,
  handleDeleteReview,
  openReviewForm,
}: IReviewOptionsDrawer) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  useClickOutside(drawerRef, isOpen, closeDrawer);

  return (
    <Drawer open={isOpen} direction='bottom' lockBackgroundScroll={true} className='rounded-t-lg dark:bg-hiq-black'>
      {isOpen && (
        <div ref={drawerRef} className='w-full p-3'>
          <div className='relative flex items-center justify-center p-4'>
            <h2 className='text-xl font-semibold'>Edit review</h2>
            <button aria-label='Close edit review' className='absolute right-3 p-1' onClick={closeDrawer}>
              <CloseIcon className='fill-hiq-black dark:fill-hiq-white' />
            </button>
          </div>
          <div className='flex flex-col items-center justify-center px-8 py-5'>
            <button onClick={openReviewForm} className='mb-6 flex flex-row items-center space-x-2'>
              <h3 className='font-medium'>Edit answer</h3>
            </button>
            <button className='mb-5' onClick={() => handleDeleteReview(reviewId)}>
              <h3 className='font-medium text-hiq-danger dark:text-hiq-danger-muted'>Delete answer</h3>
            </button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ReviewOptionsDrawer;
