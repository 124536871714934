import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthProvider from 'providers/AuthProvider';
import ReactQueryProvider from 'providers/ReactQueryProvider';
import SnackBarProvider from 'providers/SnackBarProvider';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import './index.css';
import SearchProvider from 'providers/SearchProvider';
import { DarkModeProvider } from 'providers/DarkModeProvider';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ReactQueryProvider>
        <DarkModeProvider>
          <SearchProvider>
            <SnackBarProvider>
              <App />
            </SnackBarProvider>
          </SearchProvider>
        </DarkModeProvider>
      </ReactQueryProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
