import { useContext, useLayoutEffect, useRef, useState } from 'react';
import DistanceSlider from 'components/DistanceSlider';
import FilterWrapper from 'components/FilterWrapper';
import LunchPlanList from 'components/LunchPlanList';
import NavHeader from 'components/NavHeader';
import RestaurantList from 'components/RestaurantList';
import SearchBar from 'components/SearchBar';
import TagsFilter from 'components/TagsFilter';
import useClickOutside from 'hooks/useClickOutside';
import usePageTitle from 'hooks/usePageTitle';
import { SearchContext } from 'providers/SearchProvider';
import { useOfficesQuery } from 'hooks/useOfficesQuery';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';

export default function StartPage() {
  const filterRef = useRef<HTMLDivElement>(null);
  usePageTitle('LunchQ | Start');
  const [searchInputHasFocus, setSearchInputHasFocus] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { searchQuery, distanceMinutesEnd, selectedTags, setSearchQuery, setDistanceMinutesEnd, setSelectedTags } =
    useContext(SearchContext);
  const { data: user } = useCurrentUserQuery();
  const { data: offices } = useOfficesQuery();

  const selectedOffice = offices?.find((office) => office.id === user?.officeId);
  useClickOutside(filterRef, showFilter, () => setShowFilter(false));

  const getNumberOfActiveFilters = () => {
    let numberOfActiveFilters = 0;

    if (distanceMinutesEnd !== '30' && distanceMinutesEnd !== '') {
      numberOfActiveFilters += 1;
    }

    if (selectedTags.length > 0) {
      numberOfActiveFilters += 1;
    }

    return numberOfActiveFilters;
  };

  useLayoutEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleTouchEnd = () => {
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
    };

    scrollContainer?.addEventListener('touchend', handleTouchEnd);

    return () => {
      scrollContainer?.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const clearFilters = () => {
    setDistanceMinutesEnd('');
    setSelectedTags([]);
  };

  return (
    <div className='flex h-screen max-h-webkit-fill-available flex-col justify-between overflow-hidden bg-hiq-light-pink text-hiq-black dark:bg-black dark:text-hiq-white'>
      <NavHeader />
      <div ref={scrollContainerRef} className='grow overflow-auto scrollbar-hide md:scrollbar-default'>
        <LunchPlanList
          title={`Lunch plans ${selectedOffice?.name ? `(${selectedOffice.name})` : ''}`}
          hidden={searchInputHasFocus || searchQuery !== ''}
        />
        <RestaurantList title={`Restaurants ${selectedOffice?.name ? `(${selectedOffice.name})` : ''}`} />
      </div>
      <div
        ref={filterRef}
        className='h-auto w-full rounded-t-lg bg-white dark:bg-secondary-black dark:text-hiq-black dark:text-hiq-white'
      >
        <SearchBar
          searchInputRef={searchInputRef}
          onSearch={setSearchQuery}
          onFocus={() => setSearchInputHasFocus(true)}
          onBlur={() => setSearchInputHasFocus(false)}
          toggleFilter={toggleFilter}
          showFilter={showFilter}
          activeFilters={getNumberOfActiveFilters()}
        />
        <FilterWrapper clearFilters={clearFilters} showFilter={showFilter} activeFilters={getNumberOfActiveFilters()}>
          <DistanceSlider />
          <TagsFilter />
        </FilterWrapper>
      </div>
    </div>
  );
}
