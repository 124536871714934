import { Link } from 'react-router-dom';
import { ReactComponent as GreenPlusIcon } from 'icons/green-plus.svg';

const AddPlanButton = () => {
  return (
    <Link to='add-plan' aria-label='Add lunch plan'>
      <GreenPlusIcon
        role='img'
        aria-label='green plus'
        className='absolute left-14 top-14 fill-hiq-white dark:fill-hiq-black'
      />
    </Link>
  );
};

export default AddPlanButton;
