import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageError from 'components/PageError';
import PlanIndicators from 'components/PlanIndicators';
import PlanNavigator from 'components/PlanNavigator';
import PlanParticipants from 'components/PlanParticipants';
import LunchPlanLoader from 'components/loaders/LunchPlanLoader';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { useLunchPlansQuery } from 'hooks/useLunchPlansQuery';
import usePageTitle from 'hooks/usePageTitle';
import { findCurrentPlan, getRestaurantMainImage, isToday } from 'utils';
import { NO_ADDRESS, NO_DISTANCE_MINUTES } from 'constants/no-info-paragraphs';
import { STATUS_ERROR, STATUS_LOADING } from 'constants/react-query-statuses';
import { ILunchPlan } from 'types/ILunchPlan';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as DirectionsWalkIcon } from 'icons/directions-walk.svg';
import { ReactComponent as LocationIcon } from 'icons/location.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';
import classNames from 'classnames';
import SnackBar from 'components/SnackBar';

const LunchPlan = () => {
  usePageTitle('LunchQ | Plan');
  const navigate = useNavigate();
  const { state } = useLocation();
  const userId = state?.userId;
  const { restaurantId, planId } = useParams();
  const { status: userStatus, data: user, refetch: refetchUser } = useCurrentUserQuery();
  const { status: plansStatus, data: plans, refetch: refetchPlans } = useLunchPlansQuery({ restaurantId, userId });

  if (plansStatus === STATUS_LOADING || userStatus === STATUS_LOADING) {
    return <LunchPlanLoader />;
  }

  if (plansStatus === STATUS_ERROR) {
    return (
      <PageError
        description='There was an issue when trying to load plans. Click the refresh button to retry'
        onRefresh={refetchPlans}
      />
    );
  }

  const currentPlan = plans.find((plan: ILunchPlan) => findCurrentPlan(plan, planId));

  if (!currentPlan) {
    return (
      <>
        <Link to='/' aria-label='Close' className='flex w-full justify-end p-3'>
          <CloseIcon className='dark:fill-hiq-white' />
        </Link>
        <PageError
          title='Plan not found :('
          description='Sorry, the plan you were looking for could not be found. Maybe it is an old plan or everyone has left it'
        />
      </>
    );
  }

  if (userStatus === STATUS_ERROR) {
    return (
      <PageError
        description='There was an issue when trying to load current user. Click the refresh button to retry'
        onRefresh={refetchUser}
      />
    );
  }

  const restaurant = currentPlan.restaurant;
  const currentPlanIndex = plans.findIndex((plan: ILunchPlan) => findCurrentPlan(plan, planId));

  return (
    <div
      className={classNames('flex h-screen w-full flex-col text-hiq-black dark:text-hiq-white', {
        'bg-gradient-plan-today dark:bg-gradient-plan-dark': isToday(currentPlan.departure),
        'bg-gradient-plan-tomorrow dark:bg-gradient-plan-dark ': !isToday(currentPlan.departure),
      })}
    >
      <SnackBar showNavbarAboveSnackbar={false} />
      <PlanIndicators plans={plans} currentPlanIndex={currentPlanIndex} />
      <div className='flex items-start justify-between'>
        <Link
          aria-label={`Navigate to restaurant ${restaurant.name} `}
          to={`../restaurants/${restaurant.id}`}
          className='flex w-full flex-col p-3'
        >
          <div className='flex items-center'>
            <h1 className='mr-2 text-2xl font-semibold'>{restaurant.name}</h1>
            <RightArrowIcon className='fill-current' />
          </div>
          <div className='flex items-center text-sm'>
            <LocationIcon />
            <span className='ml-1'>{restaurant.address || NO_ADDRESS}</span>
          </div>
          <div className='flex items-center text-sm'>
            <DirectionsWalkIcon />
            <span className='ml-1'>
              {restaurant.distanceMinutes ? `${restaurant.distanceMinutes} min` : NO_DISTANCE_MINUTES}
            </span>
          </div>
        </Link>
        <button aria-label='Close plan' className='p-3' onClick={() => navigate('./../..')}>
          <CloseIcon className='dark:fill-hiq-white' />
        </button>
      </div>
      <PlanNavigator
        tags={restaurant.tags}
        imageUrl={getRestaurantMainImage(currentPlan.restaurant.mainImage)}
        departure={currentPlan.departure}
        previousPlan={plans[currentPlanIndex - 1]}
        nextPlan={plans[currentPlanIndex + 1]}
        userId={userId}
      />
      <PlanParticipants restaurantId={restaurantId} plan={currentPlan} user={user} userIdInState={userId} />
    </div>
  );
};

export default LunchPlan;
