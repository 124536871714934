import dayjs from 'dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import { EImageSize } from 'types/EImageSize';
import { IImage } from 'types/IImage';
import { ILunchPlan } from 'types/ILunchPlan';
import { ILunchQUser } from 'types/ILunchQUser';

dayjs.extend(isoWeek);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

export const isToday = (date: string | undefined, today?: string) => {
  const todaysDate = dayjs(today).toISOString();
  return date ? dayjs(date).isSame(todaysDate, 'day') : false;
};

export const isAfterToday = (date: string | undefined) => {
  const todaysDate = dayjs().toISOString();
  return dayjs(date).isAfter(todaysDate);
};

export const getDateOrdinal = function (date: string) {
  const day = parseInt(dayjs(date).format('D'));

  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

// Check if user is registered on lunch plan.
export const isActiveLunchPlan = (lunchPlan: ILunchPlan, userId: string) => {
  const { takeawayUsers, eatOutUsers } = lunchPlan;
  return takeawayUsers.some(({ id }) => id === userId) || eatOutUsers.some(({ id }) => id === userId);
};

export function getRestaurantMainImage(mainImage: IImage | null, size: EImageSize = EImageSize.LARGE) {
  return mainImage ? mainImage.paths[size] : '';
}

export const getNameInitials = ({ firstName, lastName }: ILunchQUser) => {
  return firstName && lastName ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toLocaleUpperCase()}` : `?`;
};

export const findCurrentPlan = (plan: ILunchPlan, planId: string | undefined) => plan.id.toString() === planId;

// Update an object in an array
export function updateObjectInArray<T extends { id?: string }>(array: T[], updatedObject: T) {
  return array.map((obj) => (updatedObject.id === obj.id ? updatedObject : obj));
}

// Update multiple objects in an array
export function updateMultipleObjectsInArray<T extends { id?: string }>(array: T[], updatedObjects: T[]) {
  return array.map((obj) => updatedObjects.find((o) => o.id === obj.id) || obj);
}

// Remove an object from an array in an immutable way
export function removeItemFromArray<T extends { id?: string }>(array: T[], objectToRemove: T) {
  const indexOfObjectToRemove = array.findIndex(({ id }) => id === objectToRemove.id);
  return array.filter((_, index) => index !== indexOfObjectToRemove);
}

export const getPlanDeparture = ({
  today,
  departure,
  withWeeks,
}: {
  today?: string;
  departure: string;
  withWeeks: boolean;
}) => {
  const weeks = dayjs(departure).isoWeek() - dayjs(today).isoWeek();

  if (isToday(departure, today)) {
    return 'Today';
  } else if (dayjs(departure).isTomorrow()) {
    return 'Tomorrow';
  } else if (withWeeks) {
    switch (weeks) {
      case 0:
        return 'This week';
      case 1:
        return 'Next week';
      default:
        return `in ${weeks} weeks`;
    }
  } else {
    const abbreviatedMonth = dayjs(departure).format('MMM');
    const dayOfMonth = getDateOrdinal(departure);
    return `${abbreviatedMonth} ${dayOfMonth}`;
  }
};
