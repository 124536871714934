import { useState } from 'react';
import { getNameInitials } from 'utils';
import { ILunchQUser } from 'types/ILunchQUser';

interface IParticipantAvatarProps {
  user: ILunchQUser;
}

const ParticipantAvatar = ({ user }: IParticipantAvatarProps) => {
  const [imageLoadError, setImageLoadError] = useState(false);
  return (
    <>
      {imageLoadError || !user.profileImageBase64 ? (
        <div
          key={user.id}
          className='flex h-8 w-8 items-center justify-center rounded-full bg-hiq-black text-sm text-white'
        >
          {getNameInitials(user)}
        </div>
      ) : (
        <img
          className='h-8 rounded-full object-cover'
          onError={() => setImageLoadError(true)}
          src={`data:image/jpeg;base64,${user.profileImageBase64}`}
          data-testid='user-avatar'
          alt=''
        />
      )}
    </>
  );
};

export default ParticipantAvatar;
