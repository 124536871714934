import classNames from 'classnames';
import dayjs from 'dayjs';
import { PropsWithChildren, forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { getDateOrdinal, getPlanDeparture, isToday } from 'utils';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';
import 'stylesheets/react-datepicker-overrides.css';

interface IChooseDiningAlternativesProps {
  departure: string;
  onChangeDate?: (value: Date | null) => void;
}

const DiningAlternativesWrapper = ({
  departure,
  onChangeDate,
  children,
}: PropsWithChildren<IChooseDiningAlternativesProps>) => {
  const [isOpen, setIsOpen] = useState(false);
  const weekDay = dayjs(departure).format('dddd');
  const abbreviatedMonth = dayjs(departure).format('MMM');
  const dayOfMonth = getDateOrdinal(departure);
  const textColor = isToday(departure) ? 'text-hiq-pink dark:text-hiq-muted-pink' : 'text-hiq-blue';

  const DateWrapper = forwardRef<HTMLButtonElement, { onClick?: () => void }>(({ onClick }, ref) => (
    <div className='flex items-center justify-center py-3'>
      <button type='button' aria-label='Choose a date' ref={ref} onClick={onClick}>
        <h2 className='relative px-8 text-xl font-semibold uppercase'>
          {getPlanDeparture({ departure, withWeeks: true })}
          <RightArrowIcon
            className={classNames('absolute right-0 top-0.5 fill-current transition-transform', {
              'rotate-90': !isOpen,
              '-rotate-90': isOpen,
            })}
          />
        </h2>
        <h3 className={`text-sm ${textColor}`}>
          {weekDay}, {abbreviatedMonth} {dayOfMonth}
        </h3>
      </button>
    </div>
  ));

  const renderCalendarHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => (
    <div className='m-4 flex items-center justify-between text-lg'>
      <p>
        <span>{dayjs(date).format('MMMM')}</span>
        <span className='ml-1'>{dayjs(date).year()}</span>
      </p>
      <div className='flex w-16 justify-between'>
        <button
          type='button'
          aria-label='Go to previous month'
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          {!prevMonthButtonDisabled && <RightArrowIcon className='rotate-180 fill-current' />}
        </button>
        <button type='button' aria-label='Go to next month' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {!nextMonthButtonDisabled && <RightArrowIcon className='fill-current' />}
        </button>
      </div>
    </div>
  );

  return (
    <div className='relative flex h-100 w-full flex-col bg-hiq-light-pink dark:bg-black'>
      <div className='absolute top-negative-2 h-4 w-full rounded-lg bg-hiq-light-pink dark:bg-black' />
      {onChangeDate ? (
        <DatePicker
          selected={dayjs(departure).toDate()}
          onChange={onChangeDate}
          minDate={new Date()}
          maxDate={dayjs(new Date()).add(2, 'month').toDate()}
          filterDate={(date) => dayjs(date).day() !== 0 && dayjs(date).day() !== 6}
          calendarStartDay={1}
          showPopperArrow={false}
          onCalendarOpen={() => setIsOpen(true)}
          onCalendarClose={() => setIsOpen(false)}
          customInput={<DateWrapper />}
          renderCustomHeader={renderCalendarHeader}
        />
      ) : (
        <div className='flex flex-col items-center justify-center p-3'>
          <h2 className='text-xl font-semibold uppercase'>{getPlanDeparture({ departure, withWeeks: true })}</h2>
          <h3 className={`text-sm ${textColor}`}>
            {weekDay}, {abbreviatedMonth} {dayOfMonth}
          </h3>
        </div>
      )}
      {children}
    </div>
  );
};

export default DiningAlternativesWrapper;
