import { useState } from 'react';
import { Link } from 'react-router-dom';
import NavDrawer from 'components/NavDrawer';
import SnackBar from 'components/SnackBar';
import { ReactComponent as BurgerMenuIcon } from 'icons/burger-menu.svg';

const NavHeader = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const startPagePath = '/';

  const handleLinkClick = () => {
    if (window.location.pathname === startPagePath) {
      window.location.reload();
    }
  };

  return (
    <>
      <header className='z-10 flex w-full items-center justify-between bg-white px-4 dark:bg-secondary-black'>
        <Link to='/' aria-label='Start page' className='flex h-navbar items-center' onClick={handleLinkClick}>
          <img src='/lunchqlogo.png' alt='' className='w-24' />
        </Link>
        <div className='flex content-center items-center'>
          <button
            aria-expanded={showDrawer}
            aria-label='Open menu'
            onClick={() => setShowDrawer(true)}
            className='h-navbar'
          >
            <BurgerMenuIcon className='fill-hiq-black dark:fill-hiq-white' />
          </button>
          <NavDrawer showNavDrawer={showDrawer} handleCloseNavDrawer={() => setShowDrawer(false)} />
        </div>
      </header>
      <SnackBar showNavbarAboveSnackbar={true} />
    </>
  );
};

export default NavHeader;
