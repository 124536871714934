const OfficeOptionsLoader = ({ length }: { length: number }) => {
  return (
    <div className='flex flex-col' role='alert' aria-busy='true' aria-live='polite'>
      {Array.from({ length }, (_, index) => (
        <div key={index} className='mx-4 mt-2 h-18 animate-pulse rounded-lg bg-white dark:bg-hiq-black' />
      ))}
    </div>
  );
};
export default OfficeOptionsLoader;
