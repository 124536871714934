import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import Drawer from 'react-modern-drawer';
import DiningAlternativesWrapper from 'components/DiningAlternativesWrapper';
import JoinPlanButton from 'components/JoinPlanButton';
import ParticipantAvatar from 'components/ParticipantAvatar';
import ParticipantList from 'components/ParticipantList';
import useClickOutside from 'hooks/useClickOutside';
import { usePlanMutation } from 'hooks/usePlanMutation';
import { getDateOrdinal } from 'utils';
import { EDiningAlternative } from 'types/EDiningAlternative';
import { EPlanAction } from 'types/EPlanAction';
import { ILunchPlan } from 'types/ILunchPlan';
import { ILunchQUser } from 'types/ILunchQUser';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import eatOutIconUrl from 'icons/eat-out.png';
import takeAwayIconUrl from 'icons/takeaway-box.png';
import 'react-modern-drawer/dist/index.css';
import useParticipantsInterval from 'hooks/useParticipantsInterval';

interface IPlanParticipantsProps {
  restaurantId: string | undefined;
  plan: ILunchPlan;
  user: ILunchQUser;
  userIdInState?: string | undefined;
}

interface IDrawerProps {
  show: boolean;
  participants: ILunchQUser[];
  title: string;
}

const PlanParticipants = ({ restaurantId, plan, user, userIdInState }: IPlanParticipantsProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const weekDay = dayjs(plan.departure).format('dddd');
  const abbreviatedMonth = dayjs(plan.departure).format('MMM');
  const dayOfMonth = getDateOrdinal(plan.departure);
  const [drawerProps, setDrawerProps] = useState<IDrawerProps>({ show: false, participants: [], title: '' });

  const hasJoinedTakeAway = !!plan.takeawayUsers.find((p) => p.id === user.id);
  const hasJoinedEatOut = !!plan.eatOutUsers.find((p) => p.id === user.id);

  useParticipantsInterval(restaurantId, userIdInState, plan.id);

  const takeAwayMutation = usePlanMutation(
    EDiningAlternative.Takeaway,
    hasJoinedTakeAway ? EPlanAction.Leave : EPlanAction.Join,
    plan.id,
    restaurantId,
    userIdInState
  );

  const eatOutMutation = usePlanMutation(
    EDiningAlternative.EatOut,
    hasJoinedEatOut ? EPlanAction.Leave : EPlanAction.Join,
    plan.id,
    restaurantId,
    userIdInState
  );

  const openDrawer = (partList: ILunchQUser[], title: string) => {
    setDrawerProps({ show: true, participants: partList, title: title });
  };

  const closeDrawer = () => {
    setDrawerProps({ show: false, participants: [], title: '' });
  };

  useClickOutside(drawerRef, drawerProps.show, closeDrawer);

  return (
    <DiningAlternativesWrapper departure={plan.departure}>
      <JoinPlanButton
        title='Take away'
        description={plan.takeawayUsers.length ? `${plan.takeawayUsers.length} going` : 'Be the first to join!'}
        ariaLabel={`${hasJoinedTakeAway ? 'Leave' : 'Join'} take away`}
        iconUrl={takeAwayIconUrl}
        onClick={takeAwayMutation.mutate}
        disabled={takeAwayMutation.isLoading || eatOutMutation.isLoading}
        isLoading={takeAwayMutation.isLoading}
        hasJoined={hasJoinedTakeAway}
      />
      <ParticipantList
        ariaLabel='Take away participants'
        participants={plan.takeawayUsers}
        openDrawer={() => openDrawer(plan.takeawayUsers, 'Take away')}
      />
      <JoinPlanButton
        title='Eat out'
        description={plan.eatOutUsers.length ? `${plan.eatOutUsers.length} going` : 'Be the first to join!'}
        ariaLabel={`${hasJoinedEatOut ? 'Leave' : 'Join'} eat out`}
        iconUrl={eatOutIconUrl}
        onClick={eatOutMutation.mutate}
        disabled={eatOutMutation.isLoading || takeAwayMutation.isLoading}
        isLoading={eatOutMutation.isLoading}
        hasJoined={hasJoinedEatOut}
      />
      <ParticipantList
        ariaLabel='Eat out participants'
        participants={plan.eatOutUsers}
        openDrawer={() => openDrawer(plan.eatOutUsers, 'Eat out')}
      />
      <div aria-hidden={!drawerProps.show}>
        <Drawer
          open={drawerProps.show}
          direction='bottom'
          lockBackgroundScroll={true}
          className='rounded-lg dark:bg-hiq-black'
          style={{ height: 'auto' }}
        >
          <div ref={drawerRef} className='h-auto max-h-[90vh] min-h-[40vh] w-full overflow-y-auto'>
            <div className='relative flex items-center justify-center p-4'>
              <h2 className='text-xl font-semibold'>{drawerProps.title}</h2>
              <button aria-label='Close participants view' className='absolute right-3 p-1' onClick={closeDrawer}>
                <CloseIcon className='dark:fill-hiq-white' />
              </button>
            </div>
            <div className='px-8 py-3'>
              <div className='pb-5'>
                <h3 className='font-semibold'>
                  {weekDay}, {abbreviatedMonth} {dayOfMonth}
                </h3>
              </div>
              <ul className='flex flex-col items-start'>
                {drawerProps.participants.map((user) => (
                  <li key={user.id} className='flex items-center pb-3'>
                    <ParticipantAvatar user={user} />
                    <div className='ml-3'>
                      {user.firstName} {user.lastName}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Drawer>
      </div>
    </DiningAlternativesWrapper>
  );
};

export default PlanParticipants;
