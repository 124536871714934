import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_RESTAURANTS } from 'constants/query-keys';
import { ESortOption } from 'types/ESortingOptions';
import { IRestaurant } from 'types/IRestaurant';

export function useRestaurantsQuery(options?: {
  searchQuery?: string;
  distanceMinutesEnd?: string;
  selectedTags?: string[];
  enabled?: boolean;
  activeSortOption?: ESortOption;
}) {
  const params = new URLSearchParams();
  const searchQuery = options?.searchQuery;
  const distanceMinutesEnd = options?.distanceMinutesEnd;
  const selectedTagsString = options?.selectedTags?.join(',');
  const activeSortOption = options?.activeSortOption;

  if (searchQuery) {
    params.append('searchQuery', searchQuery);
  }

  if (distanceMinutesEnd) {
    params.append('distanceMinutesEnd', distanceMinutesEnd);
  }

  if (selectedTagsString) {
    params.append('tags', selectedTagsString);
  }

  if (activeSortOption && activeSortOption !== ESortOption.Default) {
    params.append('sortBy', activeSortOption);
  }

  return useQuery<IRestaurant[]>({
    queryKey: [QUERY_KEY_RESTAURANTS, { searchQuery, distanceMinutesEnd, tags: selectedTagsString, activeSortOption }],
    retry: false,
    queryFn: () => makeFetchRequest(`restaurants?${params.toString()}`),
    enabled: options?.enabled === undefined ? true : options.enabled,
  });
}
