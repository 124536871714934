const LunchPlanLoader = () => (
  <div className='min-h-screen dark:bg-black'>
    <div role='alert' className='flex animate-pulse flex-col py-5'>
      <div className='mx-4 h-0.75 bg-hiq-white dark:bg-hiq-grey' />
      <div className='mx-4 mt-6 h-7 w-7/12 rounded-md bg-hiq-white dark:bg-hiq-grey' />
      <div className='absolute bottom-0 mt-12 flex h-80 w-full flex-col items-center rounded-t-lg bg-hiq-white p-4 dark:bg-hiq-black'>
        <div className=' h-7 w-32 rounded-md bg-white dark:bg-hiq-grey' />
        <div className='mt-10 h-17.5 w-full rounded-lg bg-white dark:bg-hiq-grey' />
        <div className='mt-10 h-17.5 w-full rounded-lg bg-white dark:bg-hiq-grey' />
      </div>
    </div>
  </div>
);

export default LunchPlanLoader;
