import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { makeFetchRequest } from 'services/api';
import { updateMultipleObjectsInArray, updateObjectInArray } from 'utils';
import { QUERY_KEY_PLANS } from 'constants/query-keys';
import { EDiningAlternative } from 'types/EDiningAlternative';
import { EPlanAction } from 'types/EPlanAction';
import { ERestMethod } from 'types/ERestMethod';
import { IJoinPlanResponse } from 'types/IJoinPlanResponse';
import { ILunchPlan } from 'types/ILunchPlan';
import useSnackBar from './useSnackBar';

export function usePlanMutation(
  diningAlternative: EDiningAlternative,
  action: EPlanAction,
  planId: string,
  restaurantId: string | undefined,
  userIdInState: string | undefined
) {
  const snackBar = useSnackBar();
  const mutation = async () => {
    if (action === EPlanAction.Join) {
      return await makeFetchRequest(`plans/${planId}/${diningAlternative}-users/me`, ERestMethod.Put);
    }
    if (action === EPlanAction.Leave) {
      return await makeFetchRequest(`plans/${planId}/${diningAlternative}-users/me`, ERestMethod.Delete);
    }
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (response: ILunchPlan | IJoinPlanResponse) => {
      // set query data for current plans query
      queryClient.setQueryData(
        [QUERY_KEY_PLANS, { restaurantId, userId: userIdInState }],
        (oldData: ILunchPlan[] | undefined) => {
          if ('addedToPlan' in response && response.removedFromPlan) {
            snackBar.setMessage(
              `You have been removed from your previous lunch plan at ${response.removedFromPlan.restaurant.name}`
            );
            return updateMultipleObjectsInArray(oldData || [], [response.addedToPlan, response.removedFromPlan]);
          }
          if ('addedToPlan' in response && !response.removedFromPlan) {
            return updateObjectInArray(oldData || [], response.addedToPlan);
          }
          if ('id' in response) {
            return updateObjectInArray(oldData || [], response);
          }
        }
      );
    },
  });
}
