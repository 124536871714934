import classNames from 'classnames';
import { TAvailableSortOption } from 'types/TAvailableSortOption';
import { ReactComponent as CheckIcon } from 'icons/done-check.svg';
import { SearchContext } from 'providers/SearchProvider';
import { useContext } from 'react';

interface ISortOptionProps {
  name: TAvailableSortOption;
  label: string;
  active: boolean;
}

const SortOption = ({ name, label, active }: ISortOptionProps) => {
  const { setSortOption } = useContext(SearchContext);

  return (
    <li>
      <button
        className={classNames(
          'flex w-full justify-center border-b border-b-hiq-white p-3 text-center dark:border-b-hiq-grey dark:border-b-secondary-grey',
          {
            'text-hiq-grey': active,
          }
        )}
        type='button'
        onClick={() => setSortOption(name)}
      >
        {label}
        {active && <CheckIcon className='ml-2 dark:fill-hiq-white' />}
      </button>
    </li>
  );
};

export default SortOption;
