import { useMutation } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { ERestMethod } from 'types/ERestMethod';
import { IReviewCreationValues } from 'types/IReviewFormValues';
import useSnackBar from './useSnackBar';

export function useCreateReviewMutation(onSuccessCallback: () => void) {
  const snackBar = useSnackBar();

  const mutation = async (data: IReviewCreationValues) => {
    return await makeFetchRequest('reviews', ERestMethod.Post, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      snackBar.setMessage(`Review has been created successfully.`);
      onSuccessCallback();
    },
  });
}
