import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { QUERY_KEY_OFFICES } from 'constants/query-keys';
import { IOffice } from 'types/IOffice';

export function useOfficesQuery() {
  return useQuery<IOffice[]>({
    queryKey: [QUERY_KEY_OFFICES],
    retry: false,
    queryFn: () => makeFetchRequest('offices'),
  });
}
