import ListItemWrapper from 'components/ListItemWrapper';
import RestaurantAvatar from 'components/RestaurantAvatar';
import { getPlanDeparture, getRestaurantMainImage, isToday } from 'utils';
import { EImageSize } from 'types/EImageSize';
import { ILunchPlan } from 'types/ILunchPlan';

interface ILunchPlanListItemProps {
  lunchPlan: ILunchPlan;
  isRestaurantPage: boolean;
}

const LunchPlanListItem = ({
  lunchPlan: {
    id,
    departure,
    eatOutUsers,
    takeawayUsers,
    restaurant: { name: restaurantName, mainImage },
  },
  isRestaurantPage,
}: ILunchPlanListItemProps) => {
  const plannedForToday = isToday(departure);
  const noOfParticipants = eatOutUsers.length + takeawayUsers.length;
  const header = isRestaurantPage ? getPlanDeparture({ departure, withWeeks: false }) : restaurantName;
  const subheader = isRestaurantPage
    ? `${noOfParticipants} going`
    : plannedForToday
    ? `${noOfParticipants} going today`
    : getPlanDeparture({ departure, withWeeks: false });
  return (
    <ListItemWrapper header={header} subheader={subheader}>
      <RestaurantAvatar
        imageUrl={getRestaurantMainImage(mainImage, EImageSize.SMALL)}
        link={`plans/${id}`}
        plannedForLater={!plannedForToday}
        plannedForToday={plannedForToday}
      />
    </ListItemWrapper>
  );
};

export default LunchPlanListItem;
