import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { postImage } from 'services/api';
import { QUERY_KEY_PLANS, QUERY_KEY_RESTAURANT } from 'constants/query-keys';
import { IImage } from 'types/IImage';
import { IRestaurant } from 'types/IRestaurant';
import useSnackBar from './useSnackBar';

export function useAddImageMutation(restaurantId: number) {
  const snackBar = useSnackBar();

  const mutation = async (data: any) => {
    return await postImage(`restaurants/${restaurantId}/images`, data);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: (newImage: IImage) => {
      snackBar.setMessage(`Image has been uploaded successfully. Please wait for it to load`);
      queryClient.setQueryData([QUERY_KEY_RESTAURANT, { restaurantId }], (restaurant: IRestaurant | undefined) => {
        if (restaurant) {
          const newImages = [...restaurant.images, newImage];
          const newRestaurant = { ...restaurant, images: newImages };
          if (!restaurant.mainImage) {
            newRestaurant.mainImage = newImage;
          }
          return newRestaurant;
        }
      });
      queryClient.invalidateQueries([QUERY_KEY_PLANS, { restaurantId: restaurantId.toString() }]);
    },
    onError: () => {
      snackBar.setMessage(`Something went wrong with the image upload, please try again`);
    },
  });
}
