import { useRef } from 'react';
import Drawer from 'react-modern-drawer';
import useClickOutside from 'hooks/useClickOutside';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as CheckMark } from 'icons/done-check.svg';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';

interface IEditImageDrawer {
  open: boolean;
  imageId: number;
  closeDrawer: () => void;
  handleSelectMainImage: (imageId: number) => void;
  handleDeleteImage: (imageId: number) => void;
  isLoading: boolean;
  isMainImage: boolean;
}

const EditImageDrawer = ({
  open,
  imageId,
  closeDrawer,
  handleSelectMainImage,
  handleDeleteImage,
  isLoading,
  isMainImage,
}: IEditImageDrawer) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  useClickOutside(drawerRef, open, closeDrawer);

  return (
    <Drawer
      open={open}
      direction='bottom'
      lockBackgroundScroll={true}
      className='rounded-lg dark:bg-hiq-black'
      style={{ height: 'auto' }}
    >
      <div ref={drawerRef} className='w-full p-3'>
        <div className='relative flex items-center justify-center p-4'>
          <h2 className='text-xl font-semibold'>Edit photo</h2>
          <button aria-label='Close edit photo' className='absolute right-3 p-1' onClick={closeDrawer}>
            <CloseIcon className='dark:fill-hiq-white' />
          </button>
        </div>
        <div className='flex flex-col items-center justify-center px-8 py-5'>
          {isLoading ? (
            <LoadingSpinnerIcon role='alert' className='h-7 w-7 animate-spin' />
          ) : (
            <>
              {open && (
                <>
                  <button
                    className='mb-6 flex flex-row items-center space-x-2'
                    onClick={() => handleSelectMainImage(imageId)}
                  >
                    <h3 className='font-medium'>Use as main photo</h3>
                    {isMainImage && <CheckMark />}
                  </button>
                  <button className='mb-5' onClick={() => handleDeleteImage(imageId)}>
                    <h3 className={`font-medium text-hiq-danger dark:text-hiq-danger-muted`}>Delete photo</h3>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default EditImageDrawer;
