import classNames from 'classnames';
import { ChangeEvent } from 'react';

interface IMultiLineTextInputProps {
  placeholder: string;
  id: string;
  value: string;
  error?: string;
  maxLength?: number;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const MultiLineTextInput = ({ placeholder, id, value, error, maxLength, onChange }: IMultiLineTextInputProps) => {
  return (
    <>
      <textarea
        className={classNames('mx-4 h-60 rounded-lg border px-4 py-2 outline-none dark:bg-hiq-black', {
          'border-hiq-danger dark:border-hiq-danger-muted': Boolean(error),
          'border-hiq-black dark:border-hiq-white': !Boolean(error),
        })}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
      />
      <div className='flex px-4'>
        {error && <span className='text-hiq-danger dark:text-hiq-danger-muted'>{error}</span>}
        <div className='flex-grow'></div>
        <p className='min-w-fit pl-4 text-right text-hiq-grey dark:text-hiq-white'>
          {value.length > 0 ? `${value.length}/${maxLength} characters` : `max. ${maxLength} characters`}
        </p>
      </div>
    </>
  );
};

export default MultiLineTextInput;
