import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface ITagProps {
  text: string;
  isSelected?: boolean;
  onSelect?: (value: string) => void;
  onClick?: () => void;
  background?: 'secondary-green' | 'white';
  onRemove?: (tag: string) => void;
  visible?: boolean;
}

const Tag = ({ text, isSelected, onSelect, onClick, background, onRemove, visible = true }: ITagProps) => {
  const getBackgroundColor = () => {
    if (isSelected === undefined) {
      return background === undefined ? 'secondary-green' : background;
    }

    return isSelected ? 'secondary-green' : 'white';
  };

  return (
    <li
      onClick={onClick}
      className={classNames('mr-2 mt-1.5 inline-block text-xs font-medium leading-3 text-black', { hidden: !visible })}
      role={onClick ? 'button' : undefined}
    >
      <label
        className={classNames(
          'checked:font-lg block h-7 items-center rounded-full px-3 py-2',
          `bg-hiq-${getBackgroundColor()}`,
          {
            'cursor-pointer': onSelect || onClick,
          }
        )}
        htmlFor={text}
      >
        {onSelect && (
          <input
            onChange={(e) => onSelect(e.target.value)}
            type='checkbox'
            className='hidden'
            id={text}
            value={text}
            checked={isSelected}
          />
        )}
        <span className='align-super'>{text}</span>
        {onRemove && (
          <button type='button' className='ml-2 h-auto' onClick={() => onRemove(text)}>
            <CloseIcon height={16} width={16} />
          </button>
        )}
      </label>
    </li>
  );
};

export default Tag;
