import classNames from 'classnames';
import { ReactComponent as DoneIcon } from 'icons/done-check.svg';

interface IChooseDiningAlternativeButtonProps {
  title: string;
  description: string;
  iconUrl: string;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
  hasError: boolean;
  value: string;
}

const ChooseDiningAlternativeButton = ({
  title,
  description,
  iconUrl,
  onSelect,
  isSelected,
  hasError,
  value,
}: IChooseDiningAlternativeButtonProps) => {
  return (
    <label
      className={classNames(
        'm-3 flex items-center justify-between rounded-lg bg-white p-5 hover:cursor-pointer dark:bg-hiq-black',
        {
          'border border-hiq-black hover:cursor-default dark:border-hiq-muted-pink': isSelected,
          'active:bg-hiq-white': !isSelected,
          'border border-hiq-danger dark:border-hiq-danger-muted': hasError,
        }
      )}
      data-testid='dining-alternative-button-label'
    >
      <input type='radio' name='lunchPlanType' value={value} className='hidden' onChange={onSelect} />
      <div className='flex items-center'>
        <img alt='' src={iconUrl} className='ml-2 h-8 w-8' />
        <div className='ml-6 flex flex-col items-start justify-center'>
          <h4 className='font-medium'>{title}</h4>
          <p className='text-xs font-light'>{description}</p>
        </div>
      </div>
      {isSelected && (
        <span className='flex items-center'>
          <DoneIcon className='mr-1 dark:fill-hiq-white' />
        </span>
      )}
    </label>
  );
};

export default ChooseDiningAlternativeButton;
