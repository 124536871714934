import { useState } from 'react';
import AvatarLoader from 'components/loaders/AvatarLoader';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { getNameInitials } from 'utils';

function UserAvatar() {
  const { data: user, isLoading } = useCurrentUserQuery();
  const [imageLoadError, setImageLoadError] = useState(false);

  if (isLoading) {
    return <AvatarLoader length={1} />;
  }

  if (!user) {
    return null;
  }

  return (
    <div className='flex min-h-20.5 min-w-20.5 items-center justify-center'>
      {imageLoadError || !user.profileImageBase64 ? (
        <div className='flex h-17.5 w-17.5 items-center justify-center rounded-full bg-hiq-black text-2xl text-hiq-white'>
          {getNameInitials(user)}
        </div>
      ) : (
        <img
          className='h-19 w-19 rounded-full object-cover'
          onError={() => setImageLoadError(true)}
          src={`data:image/jpeg;base64,${user.profileImageBase64}`}
          data-testid='user-avatar'
          alt=''
        />
      )}
    </div>
  );
}

export default UserAvatar;
