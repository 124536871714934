import { Link } from 'react-router-dom';
import { ReactComponent as JoystickIcon } from 'icons/joystick.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

const AddRestaurantCard = ({ className = '' }: { className?: string }) => {
  return (
    <Link to='add-restaurant'>
      <li
        className={`mt-3 flex items-center rounded-md bg-hiq-muted-tea py-4 pl-4 pr-3 hover:cursor-pointer active:bg-hiq-muted-green dark:bg-hiq-black ${className}`}
      >
        <div className='flex min-w-20.5 justify-center'>
          <JoystickIcon />
        </div>
        <div>
          <h3 className='mb-1 text-sm font-semibold text-hiq-moss-green dark:text-hiq-white'>
            Can't find your lunch place?
          </h3>
          <p className='text-sm text-hiq-moss-green dark:text-hiq-muted-pink'>Help us add it!</p>
        </div>
        <div className='ml-auto'>
          <PlusIcon className='fill-hiq-moss-green dark:fill-hiq-muted-pink' />
        </div>
      </li>
    </Link>
  );
};

export default AddRestaurantCard;
