import classNames from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import DistanceParagraph from 'components/DistanceParagraph';
import ResponsiveTagsList from 'components/ResponsiveTagsList';
import { useRestaurantsQuery } from 'hooks/useRestaurantsQuery';
import { ESortOption } from 'types/ESortingOptions';
import { IRestaurant } from 'types/IRestaurant';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';

interface IRestaurantPickerProps {
  onSelectRestaurant: (restaurantId: number) => void;
  enabled: boolean;
  hasError: boolean;
  restaurant: IRestaurant | undefined;
  isFetchingRestaurant: boolean;
}

const RestaurantPicker = ({
  onSelectRestaurant,
  enabled,
  hasError,
  restaurant,
  isFetchingRestaurant,
}: IRestaurantPickerProps) => {
  const { data: allRestaurants, isFetching: isFetchingRestaurants } = useRestaurantsQuery({
    enabled,
    activeSortOption: ESortOption.Name,
  });
  const listRef = useRef<HTMLUListElement>(null);

  const [showRestaurantList, setShowRestaurantList] = useState(false);

  const handleRestaurantClick = (restaurantId: number) => {
    onSelectRestaurant(restaurantId);
    setShowRestaurantList(false);
  };

  const handleOnOpenRestaurantListClick = () => {
    setShowRestaurantList(!showRestaurantList);
  };

  useLayoutEffect(() => {
    if (showRestaurantList === true && listRef.current) {
      const list = listRef.current;
      const { bottom, height } = list.getBoundingClientRect();

      if (height < window.innerHeight) {
        return;
      }

      // Calculate how much the list element overflows. "bottom" is distance between top of the page and list elements bottom.
      const diffBetweenWindowAndElementHeight = bottom - window.innerHeight;
      // Subtract that diff from the elements current height and leave some room for bottom margin.
      const listMaxHeight = height - diffBetweenWindowAndElementHeight - 15;
      list.style.height = listMaxHeight + 'px';
    }
  });

  if (isFetchingRestaurants || isFetchingRestaurant) {
    return (
      <div
        role='alert'
        aria-busy='true'
        aria-live='polite'
        className='relative mx-4 h-24 animate-pulse rounded-md bg-hiq-white dark:bg-hiq-black'
      />
    );
  }

  return (
    <div className='relative mx-4'>
      <button
        className={classNames('h-21 w-full cursor-pointer rounded-md bg-white px-8 text-left dark:bg-hiq-black', {
          'border border-hiq-danger dark:border-hiq-danger-muted': hasError,
        })}
        onClick={handleOnOpenRestaurantListClick}
        aria-label='Select restaurant'
        type='button'
        disabled={!enabled}
      >
        <div className='flex'>
          <h1 className='h-6 font-semibold'>{restaurant ? restaurant.name : 'Restaurant'}</h1>

          {enabled && (
            <RightArrowIcon
              className={classNames('fill-current transition-transform', {
                'rotate-90': !showRestaurantList,
                '-rotate-90': showRestaurantList,
              })}
            />
          )}
        </div>
        <p className='text-sm'>{restaurant ? 'Create a plan for this place!' : 'Where are you going?'}</p>
      </button>

      {showRestaurantList && (
        <ul
          className='absolute z-10 mt-2 w-full divide-y overflow-auto rounded-md bg-white px-4 py-2.5 dark:divide-secondary-grey dark:bg-hiq-black'
          ref={listRef}
          aria-label='Restaurants'
        >
          {allRestaurants?.map(({ id, name, tags, distanceMinutes }) => (
            <li key={id}>
              <button
                className='w-full px-2.5 py-3.5 text-left'
                aria-label={name}
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  handleRestaurantClick(id);
                }}
              >
                <h2 className='font-semibold'>{name}</h2>

                <DistanceParagraph distanceMinutes={distanceMinutes} />

                <ResponsiveTagsList tags={tags} />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RestaurantPicker;
