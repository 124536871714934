import { Link } from 'react-router-dom';
import DistanceParagraph from 'components/DistanceParagraph';
import ResponsiveTagsList from 'components/ResponsiveTagsList';
import RestaurantAvatar from 'components/RestaurantAvatar';
import { getPlanDeparture, getRestaurantMainImage, isActiveLunchPlan, isAfterToday, isToday } from 'utils';
import { EImageSize } from 'types/EImageSize';
import { IRestaurant } from 'types/IRestaurant';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';
import { ILunchQUser } from 'types/ILunchQUser';

interface IRestaurantProps {
  restaurant: IRestaurant;
  user?: ILunchQUser;
}

const RestaurantCard = ({ restaurant, user }: IRestaurantProps) => {
  const { id, name, distanceMinutes, tags, nextPlan, mainImage } = restaurant;

  const plannedForToday = isToday(nextPlan?.departure);
  const plannedForLater = isAfterToday(nextPlan?.departure);
  const userHasJoinedPlan = nextPlan && user && isActiveLunchPlan(nextPlan, user.id);
  const departureText = nextPlan && getPlanDeparture({ departure: nextPlan.departure, withWeeks: true }).toLowerCase();

  return (
    <li className='mt-3 flex items-center rounded-md bg-white py-3 pl-2 pr-2 dark:bg-hiq-black'>
      <RestaurantAvatar
        imageUrl={getRestaurantMainImage(mainImage, EImageSize.SMALL)}
        link={nextPlan ? `plans/${nextPlan.id}` : `restaurants/${id}`}
        plannedForToday={plannedForToday}
        plannedForLater={plannedForLater}
      />
      <Link
        aria-label={`Show restaurant ${name}`}
        className='ml-3 flex w-full items-center justify-between overflow-auto'
        to={`restaurants/${id}`}
      >
        <div className='w-full overflow-auto'>
          <h3 className='mb-1 truncate font-semibold'>{name}</h3>
          <DistanceParagraph distanceMinutes={distanceMinutes} />

          {tags.length > 0 && <ResponsiveTagsList tags={tags} />}

          <p className='mt-2 text-xs'>
            {!nextPlan
              ? 'No upcoming plan, yet...'
              : userHasJoinedPlan
              ? 'Colleagues can join you here.'
              : `Join colleagues ${departureText}!`}
          </p>
        </div>
        <RightArrowIcon className='fill-current' />
      </Link>
    </li>
  );
};

export default RestaurantCard;
