import classNames from 'classnames';
import { useCurrentUserMutation } from 'hooks/useCurrentUserMutation';
import { ReactComponent as DoneCheckIcon } from 'icons/done-check.svg';
import { ReactComponent as LoadingSpinnerIcon } from 'icons/loading-spinner.svg';

interface IOfficeOptionProps {
  text: string;
  officeId: number;
  isSelected: boolean;
}

const OfficeOption = ({ text, officeId, isSelected }: IOfficeOptionProps) => {
  const currentUserMutation = useCurrentUserMutation();

  const changeOffice = () => {
    currentUserMutation.mutate({ officeId });
  };

  return (
    <button
      onClick={changeOffice}
      disabled={isSelected || currentUserMutation.isLoading}
      className={classNames(
        'mx-4 mt-2 flex h-18 items-center justify-between rounded-lg bg-white px-7 dark:bg-hiq-black',
        {
          'border-2 border-hiq-pink font-semibold dark:border-hiq-muted-pink': isSelected,
          'active:bg-hiq-pink active:text-white': !isSelected && !currentUserMutation.isLoading,
        }
      )}
    >
      <span>{text}</span>
      {currentUserMutation.isLoading ? (
        <LoadingSpinnerIcon role='alert' className='mr-3 h-5 w-5 animate-spin' />
      ) : isSelected ? (
        <DoneCheckIcon />
      ) : null}
    </button>
  );
};

export default OfficeOption;
