import { useContext, useState } from 'react';
import Tag from 'components/Tag';
import TagsLoader from 'components/loaders/TagsLoader';
import { useTagsQuery } from 'hooks/useTagsQuery';
import { STATUS_LOADING } from 'constants/react-query-statuses';
import { SearchContext } from 'providers/SearchProvider';

const MAX_TAGS_TO_RENDER = 12;

const TagsFilter = () => {
  const [showNumberOfTags, setShowNumberOfTags] = useState(MAX_TAGS_TO_RENDER);
  const { selectedTags, setSelectedTags } = useContext(SearchContext);
  const { data: tags, status } = useTagsQuery();

  if (status === STATUS_LOADING) {
    return <TagsLoader length={10} />;
  }

  if (!tags) {
    return null;
  }

  const handleSelect = (selectedTag: string) => {
    if (selectedTags.includes(selectedTag)) {
      // Remove
      setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));
    } else {
      // Add
      setSelectedTags([...selectedTags, selectedTag]);
    }
  };

  return (
    <div className='my-2 pb-4 pt-2'>
      <h2 className='mb-3.5'>Tags</h2>
      <ul className='w-full break-words' aria-label='Tags'>
        {tags.slice(0, showNumberOfTags).map(({ name }) => (
          <Tag key={name} text={name} isSelected={selectedTags.includes(name)} onSelect={handleSelect} />
        ))}

        {tags.length > showNumberOfTags && (
          <Tag
            background='white'
            onClick={() => setShowNumberOfTags(tags.length)}
            text={`+${tags.length - showNumberOfTags} more`}
          />
        )}
      </ul>
    </div>
  );
};

export default TagsFilter;
