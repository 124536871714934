import ModalHeader from 'components/ModalHeader';
import RestaurantForm from 'components/RestaurantForm';
import { useCreateRestaurantMutation } from 'hooks/useCreateRestaurantMutation';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { useOfficesQuery } from 'hooks/useOfficesQuery';
import usePageTitle from 'hooks/usePageTitle';
import { IRestaurantFormValues } from 'types/IRestaurantFormValues';

const AddRestaurant = () => {
  usePageTitle('LunchQ | Add Restaurant');
  const { data: user } = useCurrentUserQuery();
  const { data: offices } = useOfficesQuery();
  const selectedOffice = offices?.find((office) => office.id === user?.officeId);

  const createRestaurantMutation = useCreateRestaurantMutation();

  const onSubmit = (values: IRestaurantFormValues) => {
    createRestaurantMutation.mutate({
      name: values.name,
      address: values.address,
      distanceMinutes: Number(values.distanceMinutes),
      webpage: values.webpage,
      tags: values.tags,
    });
  };

  return (
    <div className='flex min-h-screen w-full flex-col text-hiq-black dark:bg-hiq-black dark:text-hiq-white'>
      <ModalHeader title='Add restaurant' subtitle={`to LunchQ ${selectedOffice ? selectedOffice.name : ''}`} />
      <RestaurantForm
        initialValues={{ name: '', address: '', distanceMinutes: 1, webpage: '', tags: [] }}
        onSubmit={onSubmit}
        isLoading={createRestaurantMutation.isLoading}
        submitButtonText='Add restaurant'
      />
    </div>
  );
};

export default AddRestaurant;
