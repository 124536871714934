import { useMutation } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';
import { ERestMethod } from 'types/ERestMethod';
import useSnackBar from './useSnackBar';

export function useDeleteReviewMutation(onSuccessCallback: () => void) {
  const snackBar = useSnackBar();

  const mutation = async (reviewId: number) => {
    return await makeFetchRequest(`reviews/${reviewId}`, ERestMethod.Delete);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      snackBar.setMessage(`Review has been deleted successfully.`);
      onSuccessCallback();
    },
  });
}
