import { useMutation } from '@tanstack/react-query';
import useSnackBar from 'hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { makeFetchRequest } from 'services/api';
import { ERestMethod } from 'types/ERestMethod';

export function useDeleteRestaurantMutation(restaurantId: number, restaurantName: string) {
  const snackBar = useSnackBar();
  const navigate = useNavigate();

  const mutation = async () => {
    return await makeFetchRequest(`restaurants/${restaurantId}`, ERestMethod.Delete);
  };

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      snackBar.setMessage(`${restaurantName} has been deleted successfully.`);
      navigate('..');
    },
  });
}
