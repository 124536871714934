import { useEffect } from 'react';

const useClickOutside = (protectedElementRef: React.RefObject<HTMLElement>, isOpen: boolean, func: () => void) => {
  return useEffect(() => {
    if (protectedElementRef.current && isOpen) {
      const handleClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const targetIsOutsideProtectedElement =
          protectedElementRef.current && !protectedElementRef.current.contains(target);
        if (targetIsOutsideProtectedElement) {
          func();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }
  }, [protectedElementRef, isOpen, func]);
};

export default useClickOutside;
